import { SweetAlertOptions } from 'sweetalert2'

import { Red, White } from '@styles/Color'

export const confirmParsedDataAbort: SweetAlertOptions = {
	title: 'Czy na pewno chcesz odrzucić dane pobrane z pliku?',
	text: 'Informacje pochodzące z profilu, nie zostaną usunięte. Przekierujemy Cię do strony profilu.',
	showCancelButton: true,
	cancelButtonText: 'Anuluj',
	showConfirmButton: true,
	confirmButtonText: 'Odrzuć dane z pliku',
	confirmButtonColor: Red[500],
	focusCancel: true,
}
export const confirmParserAbort: SweetAlertOptions = {
	title: 'Czy chcesz pominąć przetwarzanie pliku?',
	showCancelButton: true,
	cancelButtonText: `<span style='color:${ White }'>Pomiń</span>`,
	showConfirmButton: true,
	confirmButtonText: 'Anuluj',
	cancelButtonColor: Red[500],
	reverseButtons: true,
	focusCancel: true,
}

export const confirmParseProcessAbort: SweetAlertOptions = {
	title: 'Czy chcesz zakończyć przetwarzanie pliku?',
	text: 'Przetwarzanie pliku w toku, akcja będzie skutkować zatrzymaniem przetwarzania załączonego pliku i powrotem do kreatora.',
	showCancelButton: true,
	cancelButtonText: 'Zakończ',
	showConfirmButton: true,
	confirmButtonText: 'Anuluj',
	reverseButtons: true,
}

export const confirmCreatorAbort: SweetAlertOptions = {
	title: 'Czy na pewno chcesz pominąć kreator profilu?',
	text: 'Wprowadzone informacje zostaną zapisane w profilu.',
	showCancelButton: true,
	confirmButtonText: 'Anuluj',
	showConfirmButton: true,
	cancelButtonText: 'Pomiń kreator',
	reverseButtons: true,
}
