import Style, { css } from 'react-emotion'

import { SxProps } from '@mui/material'
import { Alpha, Blue, Gray, Red, White } from '@styles/Color'
import MediaQuery from '@styles/MediaQueries'

export const Layout = Style('div')({
	display: 'grid',
	alignItems: 'start',
	gap: 20,
	marginTop: 20,

	[MediaQuery.Medium]: {
		marginInline: 0,
	},

	[MediaQuery.Xlarge]: {
		gridTemplateColumns: '1fr 400px',
	},
})

export const SectionsWrapper = Style('div')({
	borderRadius: 10,
	backgroundColor: White,

	[MediaQuery.Xlarge]: {
		gridRow: 1,
	},
})

export const SectionContainer = Style('div')<{ grayBackground?: boolean }>(
	({ grayBackground }) => ({
		backgroundColor: grayBackground ? Gray[50] : 'transparent',
		padding: '32px 10px',
		borderBottom: '2px solid ' + Gray[100],

		'&:last-child': {
			borderBottom: 'none',
		},

		[MediaQuery.Medium]: {
			paddingInline: 20,
		},
	}),
)

export const SectionTitle = Style('p')({
	fontSize: 16,
	fontWeight: 700,

	[MediaQuery.Large]: {
		fontSize: 18,
	},
})

export const SectionSubtitle = Style('p')({
	fontSize: 15,
	fontWeight: 700,

	[MediaQuery.Large]: {
		fontSize: 16,
	},
})

export const TitleWrapper = Style('div')({
	display: 'flex',
	alignItems: 'start',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	gap: '8px 32px',
	marginBottom: 16,

	'> p': {
		marginBottom: 0,
	},
})

export const SectionContent = Style('div')({
	marginBottom: 24,
})

export const ListItem = Style('li')({
	marginBottom: 12,

	'&::marker': {
		color: Blue[800],
		fontSize: 20,
	},

	p: {
		marginBottom: 2,

		'&:last-child': {
			marginBottom: 0,
		},
	},
})

export const EditableSection = Style('div')<{ twoButtons?: boolean }>(
	({ twoButtons }) => ({
		display: 'grid',
		gridTemplateColumns: twoButtons ? '1fr 90px' : '1fr 40px',
		columnGap: 20,
		alignItems: 'center',

		p: {
			marginBottom: 2,

			'&:last-child': {
				marginBottom: 0,
			},
		},
	}),
)

export const sectionButton = css({
	'&.MuiButton-outlinedPrimary': {
		display: 'block',
		borderColor: Blue[800],
		padding: 20,
		textTransform: 'initial',
		marginInline: 'auto',
		maxWidth: 300,
		width: '100%',
		borderRadius: 100,
	},
})

export const InfoBadge = Style('div')({
	backgroundColor: Blue[50],
	fontSize: 12,
	borderRadius: 50,
	color: Blue[800],
	padding: '4px 12px',
	display: 'flex',
	gap: 8,
	alignItems: 'center',
})

export const iconMargin = css({
	marginRight: 8,
})

export const StatusSelectWrapper = Style('div')({
	maxWidth: 'calc(100vw - 50px)',
	width: '100%',

	[MediaQuery.Medium]: {
		width: '50%',
	},
})

export const statusSelect = css({
	'.MuiInputBase-root.MuiOutlinedInput-root': {
		borderRadius: '100px !important',
	},

	'.MuiSelect-select.MuiSelect-outlined': {
		fontSize: '13px',
		padding: '10px 14px',

		'> span': {
			marginBottom: 0,
			verticalAlign: 'text-top',
		},
	},

	'.MuiOutlinedInput-notchedOutline': {
		border: 'none',
	},
})

export const StatusBullet = Style('span')<{ color: string }>(({ color }) => ({
	display: 'inline-block',
	height: 14,
	width: 14,
	borderRadius: 20,
	backgroundColor: color,
	marginBottom: 2,
	marginRight: 6,
	flexShrink: 0,
}))

export const TextButton = Style('button')<{ weight?: number }>(
	({ weight }) => ({
		color: Blue[800],
		fontWeight: weight ? weight : 700,
		fontSize: 'inherit',
	}),
)

export const progressBar = (positivePercentage: boolean) =>
	css({
		'&.MuiLinearProgress-root': {
			height: 10,
			borderRadius: 5,
			backgroundColor: Gray[100],
		},

		'> .MuiLinearProgress-bar': {
			borderRadius: 5,
			backgroundColor: positivePercentage ? Blue[800] : Red[600],
		},
	})

export const CompletionIcon = Style('span')<{ positivePercentage: boolean }>(
	({ positivePercentage }) => ({
		height: 28,
		width: 28,
		backgroundColor: positivePercentage ? Blue[100] : Red[50],
		color: Blue[800],
		borderRadius: 50,
		marginRight: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
)

export const CompletionInfo = Style('div')({
	display: 'flex',
	alignItems: 'center',
	marginBottom: 10,
})

export const CompletionBox = Style('div')({
	display: 'grid',
	alignItems: 'start',
	gap: 24,

	'> button': {
		marginRight: 'auto',
	},

	[MediaQuery.Medium]: {
		gridTemplateColumns: '1fr 1fr',

		'> button': {
			marginRight: 0,
			marginLeft: 'auto',
		},
	},
})

export const imageZone = css({
	border: '1px dashed ' + Alpha(Blue[900], 0.2),
	borderRadius: 6,
	cursor: 'pointer',
	height: 140,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
	transition: 'background-color 0.2s',
})

export const dragActive = css({
	background: Blue[50],
})

export const StaticImage = Style('img')({
	borderRadius: 100,
	maxHeight: 100,
	maxWidth: 100,
})

export const StaticImageBlock = Style('div')({
	position: 'relative',
	width: 'fit-content',
	marginInline: 'auto',
})

export const ImageEditButton = Style('div')({
	position: 'absolute',
	bottom: 0,
	right: 0,
	transform: 'translate(15%, 15%)',
})

export const ImageDeleteButton = Style('div')({
	position: 'absolute',
	right: 2,
	top: 2,
})

export const ImageRequirements = Style('p')({
	color: Gray[600],
	fontSize: 11,
	marginBottom: 28,
	marginTop: 4,
})

export const collapseContainer = css({
	width: '100%',
	height: 0,
	overflow: 'hidden',
	transition: 'height 150ms ease',
})

export const CollapseButton = Style('button')<{ open: boolean }>(
	({ open }) => ({
		color: Blue[800],
		marginBlock: 8,

		'> span': {
			transition: 'transform 150ms ease',
			transform: open ? 'rotateX(180deg)' : 'rotateX(0deg)',
		},
	}),
)

export const ParseBlock = Style('div')({
	backgroundColor: Blue[50],
	padding: '5px 10px 10px 10px',
	borderRadius: 10,
	marginBottom: 18,
	display: 'flex',
	gap: 20,
	flexWrap: 'wrap',
	alignItems: 'center',
	justifyContent: 'space-between',
})

export const DownloadCvBlock = Style('div')({
	display: 'flex',
	justifyContent: 'center',
})

export const editButton = css({
	'&.MuiIconButton-colorPrimary': {
		backgroundColor: Blue[50],
		color: Blue[800],
		height: 40,
		width: 40,
		fontSize: 18,

		'&:hover': {
			backgroundColor: Blue[100],
			color: Blue[800],
		},
	},
})

export const deleteButton = css({
	'&.MuiIconButton-colorError': {
		backgroundColor: Red[50],
		color: Red[800],
		height: 40,
		width: 40,
		fontSize: 18,

		'&:hover': {
			backgroundColor: Red[100],
			color: Red[800],
		},
	},
})

export const ContractsWrapper = Style('div')({
	display: 'flex',
	gap: 3,
})

export const noImage = css({
	height: '100%',
	width: '100%',
	textTransform: 'uppercase',
})

export const CropperButtonsBlock = Style('div')({
	display: 'grid',
	gap: 15,
	gridTemplateColumns: 'auto auto',
	marginTop: 10,
	width: 'fit-content',
})

export const CropperImage = Style('div')({
	display: 'grid',
	gridTemplateRows: '1fr auto auto',
	justifyItems: 'center',

	[MediaQuery.Medium]: {
		gridColumn: 1,
		gridRow: '1/span 3',
		width: 'min-content',
	},
})

export const CropperImageContent = Style('div')({
	display: 'inline-block',
	height: 275,
	overflow: 'hidden',
	position: 'relative',
	width: 275,

	[MediaQuery.Medium]: {
		height: 300,
		width: 300,
	},
})

export const CropperRotateButtons = Style('div')({
	display: 'grid',
	gap: 15,
	gridTemplateColumns: 'auto auto',
	margin: 10,
	width: 'min-content',
	height: 'min-content',
})

export const CropperSliderButton = Style('div')({
	alignItems: 'center',
	display: 'flex',
	gap: 25,
	height: 'min-content',
	justifyContent: 'center',
	marginTop: 20,
	width: '100%',
})

export const ImageCropperRoot = Style('div')({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	alignItems: 'center',
	width: '100%',
})

export const VisuallyHiddenInput = Style('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
})

export const EditContentWrapper = Style('div')<{ smallPaddingTop?: boolean }>(
	({ smallPaddingTop = false }) => ({
		paddingBottom: 48,
		paddingTop: smallPaddingTop ? 24 : 48,

		[MediaQuery.Medium]: {
			maxWidth: 480,
			marginInline: 'auto',
		},
	}),
)

export const InputWithTitle = Style('div')({
	display: 'flex',
	flexDirection: 'column',
	gap: 5,

	'> p': {
		fontWeight: 800,
	},
})

export const EditItem = Style('div')<{ applyMargin?: boolean }>(
	({ applyMargin = false }) => ({
		display: 'grid',
		gridTemplateColumns: '1fr 40px',
		gap: 8,

		'&:not(:last-child)': {
			marginBottom: applyMargin ? 24 : 0,
		},
	}),
)

export const TooltipText = Style('div')({
	background: Gray[200],
	borderRadius: 10,
	display: 'none',
	fontSize: 12,
	padding: 5,
	position: 'absolute',
	right: 5,
	transform: 'translateX(45%)',
	width: 365,
	zIndex: 999,
})

export const TitleWithTooltip = Style('div')({
	display: 'flex',
	gap: 5,

	'> p': {
		fontWeight: 800,
	},
})

export const TooltipWrapper = Style('span')({
	position: 'relative',

	'.tooltip-trigger': {
		cursor: 'pointer',
		color: Blue[400],
		'&:hover': {
			'+ .tooltip-content': {
				display: 'block',
			},
		},
	},
})

export const NumericInputWrapper = Style('div')({
	alignItems: 'center',
	display: 'flex',
	gap: 10,
})

export const SummaryPreview = Style('p')({
	whiteSpace: 'pre-wrap',
})

export const deleteInlineIcon = css({
	marginTop: 8,
})

export const formField = css({
	'.MuiFormHelperText-root': {
		marginTop: 0,
		marginBottom: 6,
	},
})

export const switchField = css({
	marginBottom: 20,
})

export const infoAlert = css({
	'&.MuiAlert-standardInfo': {
		color: Blue[800],
		marginBottom: 28,

		'.MuiAlert-icon': {
			color: Blue[800],
		},
	},
})

export const selectField = css({
	'&.MuiFormControl-root': {
		marginBottom: 20,
	},
})

export const chip: SxProps = {
	background: 'none',
	borderColor: Gray[700],
	borderRadius: 30,
	color: Gray[700],
	fontSize: 14,
	height: 48,

	'&:hover, &.MuiChip-filled': {
		background: Blue[50],
		borderColor: Blue[700],
		border: 1,
		color: Blue[700],
	},
}

export const radio = css({
	border: '1px solid ' + Gray[400],
	borderRadius: 30,
	boxSizing: 'content-box',
	marginBottom: 20,
	marginLeft: '0px !important',
	marginRight: '5px !important',
	padding: 12,
	transition: 'border-color 0.1s',
	width: 100,

	'& .MuiFormControlLabel-label': {
		fontSize: '14px !important',
		margin: '0 auto',
	},

	'&:hover': {
		border: '1px solid ' + Gray[800],
	},

	'&.Mui-disabled:hover': {
		border: '1px solid ' + Gray[400],
	},
})

export const radioRoot = css({
	'&.MuiRadio-root': {
		display: 'none',
	},
})

export const radioActive = (redOnSelect?: boolean) =>
	css({
		background: redOnSelect ? Red[600] : Blue[800],
		borderColor: redOnSelect ? Red[600] : Blue[800],
		color: White,

		'&.Mui-disabled': {
			background: Gray[600],
		},

		'&.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled': {
			color: White + ' !important',
		},

		'&.MuiFormControlLabel-root.Mui-disabled': {
			borderColor: Gray[600] + ' !important',
		},
	})

export const basicSwitch = (checked: boolean) =>
	css({
		width: 'fit-content',
		marginBottom: 24,

		'& .MuiSwitch-track, & .MuiSwitch-thumb': {
			background: checked ? Blue[800] + ' !important' : '',
		},
	})

export const fieldWithRedAsterisk = css({
	'& .MuiFormLabel-asterisk': {
		color: Red[500],
	},
})
