import React from 'react'

import { Progress } from '../Creator/Creator.Style'

type Props = {
	value: number
}

const ProgressBar = ({ value }: Props) => {
	return (
		<Progress value={ value > 100 ? 100 : value }>
			<div></div>
		</Progress>
	)
}

export default ProgressBar
