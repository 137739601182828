import React, { useState } from 'react'

import Button from '@mui/material/Button'
import { Language } from '@pages/employee/Profile/interfaces'
import { LanguageLevel } from '@pages/employee/Profile/Partials/Sections/LanguagesSection'
import LanguagesEdit from '@pages/employee/Profile/Partials/Sections/LanguagesSection/LanguagesEdit'

import {
	ListItem, sectionButton,
	SectionContainer,
	SectionContent,
	SectionTitle,
} from '@pages/employee/Profile/Profile.Style'

interface LanguageProps {
	languages: Language[]
	updateData: (data: Language[]) => void
}

const Language = ({ languages, updateData }: LanguageProps) => {
	const [ showEdit, setShowEdit ] = useState<boolean>(false)

	return languages.length > 0 && (
		<>
			<SectionContainer>
				<SectionContent>
					<SectionTitle>Języki obce</SectionTitle>
					<ul>
						{ languages.map(({ name, level }, index) => (
							<ListItem key={ 'language_' + index }>
								<p>{ name.title }</p>
								{ level && <p>{ LanguageLevel[level] }</p> }
							</ListItem>
						)) }
					</ul>
				</SectionContent>

				<Button
					variant='outlined'
					className={ sectionButton }
					onClick={ () => setShowEdit(true) }
				>
					{ languages.length ? 'Edytuj' : 'Dodaj' } języki obce
				</Button>
			</SectionContainer>

			{ showEdit && <LanguagesEdit
				close={ () => setShowEdit(false) }
				languages={ languages }
				returnData={ updateData }
			/> }
		</>
	)
}

export default Language
