import React, { useEffect, useRef } from 'react'

import { Alert } from '@mui/material'
import Button from '@mui/material/Button'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { BasicType, BasicTypeWithID } from '@pages/employee/Profile/interfaces'
import AutocompleteField from '@pages/employee/Profile/Partials/AutocompleteField'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { SectionEdit } from '@pages/employee/Profile/Partials/SectionEdit'
import { filterEmptyObjects, getRandomID } from '@pages/employee/Profile/utils'
import { Icon } from '@sharedComponents/React'

import {
	deleteInlineIcon,
	EditContentWrapper,
	EditItem,
	infoAlert,
} from '@pages/employee/Profile/Profile.Style'

interface Props {
	close: () => void
	returnData?: (data: BasicType[]) => void
	licences: BasicType[]
}

const emptyLicence: BasicType = {
	value: null,
	title: '',
}

const LicencesEdit = ({ close, licences, returnData }: Props) => {
	const { saveData, currentValue, errors, addItem, removeItem, handleChange } =
		useEditSection('licences', licences.length ? licences : [emptyLicence])
	const itemsToRender = useRef<BasicTypeWithID[]>(
		currentValue.map(value => ({
			...value,
			renderID: getRandomID(),
		})),
	)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const itemAdded = useRef<boolean>(false)

	const handleSave = async () => {
		let shouldClose = false
		if (returnData) {
			returnData(filterEmptyObjects(currentValue))
			shouldClose = true
		} else {
			shouldClose = await saveData()
		}
		if (shouldClose) {
			close()
		}
	}

	useEffect(() => {
		if (!itemAdded.current) return

		buttonRef.current.scrollIntoView({
			behavior: 'smooth',
		})
		itemAdded.current = false
	}, [currentValue])

	return (
		<SectionEdit
			header='Uprawnienia / certyfikaty'
			saveFn={ handleSave }
			closeFn={ close }
		>
			<EditContentWrapper smallPaddingTop>
				<Alert severity='info' classes={{ standardInfo: infoAlert }}>
					Wpisuj każde uprawnienie osobno
				</Alert>

				{ itemsToRender.current.map((licence, index) => (
					<EditItem key={ licence.renderID }>
						<AutocompleteField
							type='licence'
							dataValue={ licence }
							error={ errors && errors?.[index]?.title }
							handleChange={ (value: BasicType) =>
								handleChange(value, `[${ index }]`)
							}
							multiline
						/>

						<RoundedButton
							icon='trash-can'
							color='error'
							onClick={ () => {
								removeItem(index)
								itemsToRender.current.splice(index, 1)
							} }
							className={ deleteInlineIcon }
						/>
					</EditItem>
				)) }

				<Button
					ref={ buttonRef }
					onClick={ () => {
						addItem(emptyLicence)
						itemsToRender.current.push({
							...emptyLicence,
							renderID: getRandomID(),
						})
						itemAdded.current = true
					} }
					startIcon={ <Icon type='plus' /> }
				>
					Dodaj uprawnienie / certyfikat
				</Button>
			</EditContentWrapper>
		</SectionEdit>
	)
}

export default LicencesEdit
