import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import TextField from '@mui/material/TextField'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { BasicDataForEdit } from '@pages/employee/Profile/interfaces'
import Icon from '@sharedComponents/React/Icon'

import {
	Info, SectionBlock,
	SectionTitle,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'

export type Stage1Data = Omit<BasicDataForEdit, 'photo' | 'birthdate' | 'location'>

export type Stage1Ref = {
	validateAll: () => boolean
	getCurrentData: () => Stage1Data
}

export declare type Stage1Field = keyof Stage1Data

interface Stage1Props {
	updateForm: (data: Stage1Data) => void
	basicData: BasicDataForEdit
	loading: string[]
}

const Stage1 = forwardRef(function Stage1({
	updateForm, basicData, loading }: Stage1Props, ref) {
	const [ edited, setEdited ] = useState<Array<keyof Stage1Data>>([])

	const { errors, handleChange, currentValue, validate } =
		useEditSection('basicData', basicData)

	useEffect(() => {
		handleChange(basicData)
		for (const [ key, value ] of Object.entries(basicData)) {
			if (value) {
				editChange(key as Stage1Field)
			}
		}
	}, [basicData])

	useImperativeHandle(ref, () => {
		return {
			validateAll(): boolean {
				return validate()
			},
			getCurrentData(): Stage1Data {
				return { firstName: currentValue.firstName, lastName: currentValue.lastName, phone: currentValue.phone }
			}
		}
	})

	const sendUpdate = () => {

		if (edited.length === 0 || !validate(edited)) {
			return
		}
		updateForm({
			firstName: currentValue.firstName,
			lastName: currentValue.lastName,
			phone: currentValue.phone,
		})
	}

	const editChange = (fieldName: keyof Stage1Data) => {
		if (!edited.includes(fieldName)) {
			setEdited(prev => ([ ...prev, fieldName ]))
		}
	}

	const changeHandler = (fieldName: keyof Stage1Data, value: string) => {
		editChange(fieldName)
		handleChange(value, `[${ fieldName }]`)
	}

	const blurHandler = (fieldName: keyof Stage1Data) => {
		editChange(fieldName)
		sendUpdate()
	}

	return (
		<div>
			<SectionTitle style={{ textAlign: 'center' }}>Od teraz pracujemy dla Ciebie!</SectionTitle>
			<SectionBlock>Uzupełnij profil, a my znajdziemy dla Ciebie najlepsze oferty pracy.</SectionBlock>
			<SectionBlock>
				<TextField
					required
					label="Imię"
					value={ currentValue.firstName }
					onChange={ ({ target }) => changeHandler('firstName', target.value) }
					onBlur={ () => blurHandler('firstName') }
					error={ !!errors?.firstName }
					helperText={ errors?.firstName || '' }
					fullWidth
					disabled={ loading.indexOf('firstName') > -1 }
				/>
			</SectionBlock>
			<SectionBlock>
				<TextField
					required
					label="Nazwisko"
					value={ currentValue.lastName }
					onChange={ ({ target }) => changeHandler('lastName', target.value) }
					onBlur={ () => blurHandler('lastName') }
					error={ !!errors?.lastName }
					helperText={ errors?.lastName || '' }
					fullWidth
					disabled={ loading.indexOf('lastName') > -1 }
				/>
			</SectionBlock>
			<SectionBlock>
				<TextField
					label="Telefon"
					required
					value={ currentValue.phone }
					onChange={ ({ target }) => changeHandler('phone', target.value) }
					onBlur={ () => blurHandler('phone') }
					error={ !!errors?.phone }
					helperText={ errors?.phone || '' }
					fullWidth
					disabled={ loading.indexOf('phone') > -1 }
				/>
			</SectionBlock>
			<Info><Icon type="info-circle" weight="light"/>Twój telefon udostępniamy tylko w celach rekrutacji.</Info>
		</div>
	)
})
Stage1.displayName = 'Stage1'

export default Stage1
