import React, { useEffect, useRef } from 'react'

import { Alert } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { SectionEdit } from '@pages/employee/Profile/Partials/SectionEdit'
import { filterEmptyObjects } from '@pages/employee/Profile/utils'
import { Icon } from '@sharedComponents/React'

import {
	deleteInlineIcon,
	EditContentWrapper,
	EditItem,
	infoAlert,
} from '@pages/employee/Profile/Profile.Style'

interface Props {
	close: () => void
	returnData?: (data: string[]) => void
	interests: string[]
}

const InterestsEdit = ({ interests, close, returnData }: Props) => {
	const { currentValue, errors, saveData, addItem, handleChange, removeItem } =
		useEditSection('interests', interests.length ? interests : [''])
	const buttonRef = useRef<HTMLButtonElement>(null)
	const itemAdded = useRef<boolean>(false)

	const handleSave = async () => {
		let shouldClose = false
		if (returnData) {
			returnData(filterEmptyObjects(currentValue))
			shouldClose = true
		} else {
			shouldClose = await saveData()
		}
		if (shouldClose) {
			close()
		}
	}

	useEffect(() => {
		if (!itemAdded.current) return

		buttonRef.current.scrollIntoView({
			behavior: 'smooth',
		})
		itemAdded.current = false
	}, [currentValue])

	return (
		<SectionEdit header='Zainteresowania' closeFn={ close } saveFn={ handleSave }>
			<EditContentWrapper smallPaddingTop>
				<Alert severity='info' classes={{ standardInfo: infoAlert }}>
					Wpisuj każde zainteresowanie osobno
				</Alert>

				{ currentValue.map((interest, index) => (
					<EditItem key={ index }>
						<TextField
							value={ interest }
							variant='outlined'
							onChange={ ({ target }) =>
								handleChange(target.value, `[${ index }]`)
							}
							error={ errors && !!errors?.[index] }
							helperText={ (errors && errors?.[index]) || ' ' }
							fullWidth
							multiline
						/>

						<RoundedButton
							icon='trash-can'
							color='error'
							onClick={ () => removeItem(index) }
							className={ deleteInlineIcon }
						/>
					</EditItem>
				)) }

				<Button
					ref={ buttonRef }
					onClick={ () => {
						addItem('')
						itemAdded.current = true
					} }
					startIcon={ <Icon type='plus' /> }
				>
					Dodaj zainteresowanie
				</Button>
			</EditContentWrapper>
		</SectionEdit>
	)
}

export default InterestsEdit
