import * as React from 'react'

import { IconButton } from '@sharedComponents/React/Button'

import { Black } from '@styles/Color'
import { closeButton, Backdrop, Container, Content, ModalFooter, ModalHeader } from './Modal.Styled'

interface ModalProps {
  backdropColor?: boolean,
  children?: React.ReactNode,
  className?: string,
  closeButton?: boolean,
  disableBackdropClick?: boolean,
  footer?: React.ReactNode,
  fullScreenMedium?: boolean,
  id?: string,
  notFullScreen?: boolean
  header?: React.ReactNode,
  onClose?: () => void,
  show?: boolean,
  sizeClose?: number,
  title?: React.ReactNode,
}

export default class Modal extends React.Component<ModalProps> {

	private static defaultProps = {
		children: null,
		closeButton: true,
		onClose: () => void 0,
		show: false,
		title: '',
	}

	private backdrop: HTMLDivElement
	private container: HTMLDivElement

	public render() {
		const { props } = this

		if (!props.show) {
			return null
		}

		const containerProps: { [key: string]: any } = {
			innerRef: (element) => { this.container = element },
		}

		if (props.className) {
			containerProps.className = props.className
		}

		return (
			<Backdrop backdropColor={ props.backdropColor }
				onClick={ props.disableBackdropClick ? null : this.handleBackdropClick }
				innerRef={ (element) => { this.backdrop = element } }>
				<Container
					{ ...containerProps }
					notFullScreen={ props.notFullScreen }
					fullScreenMedium={ props.fullScreenMedium }
				>
					{ props.closeButton && (
						<IconButton className={ closeButton } color={ Black } onClick={ props.onClose }
							iconData={ { type: 'times', weight: 'regular' } }/>
					) }
					{ props.header && <ModalHeader>{ props.header }</ModalHeader> }
					<Content>{ props.children }</Content>
					{ props.footer && <ModalFooter>{ props.footer }</ModalFooter> }
				</Container>
			</Backdrop>
		)
	}

	private handleBackdropClick = (event) => {
		if (event.target === this.backdrop) {
			this.props.onClose()
		}
	}
}
