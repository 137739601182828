import MediaQuery from '@styles/MediaQueries'
import Style from 'react-emotion'
import { ButtonProps } from './index'

export const ButtonRoot = Style('button')((props: ButtonProps) => ({
  backgroundColor: props.backgroundColor,
  border: props.backgroundColor === 'transparent' ? '1px solid ' + props.color : 'none',
  borderRadius: 23,
  color: props.color,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: 15,
  fontWeight: 700,
  lineHeight: '25px',
  opacity: props.disabled ? 0.5 : 1,
  padding: '7px 30px',
  width: props.width || 'auto',

  [MediaQuery.Large]: {
    fontSize: props.desktopChanges ? 12 : 15,
    padding: props.desktopChanges ? '2px 35px' : '7px 30px',
  },
}))

export const LinkButtonRoot = Style('a')((props: ButtonProps) => ({
  backgroundColor: props.backgroundColor,
  border: props.backgroundColor === 'transparent' ? '1px solid ' + props.color : 'none',
  borderRadius: 23,
  color: props.color,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: 15,
  fontWeight: 700,
  lineHeight: '25px',
  opacity: props.disabled ? 0.5 : 1,
  padding: '7px 30px',
  textAlign: 'center',
  width: props.width || 'auto',

  '&:hover, &:focus': {
    color: props.color,
  },

  [MediaQuery.Large]: {
    fontSize: props.desktopChanges ? 12 : 15,
    padding: props.desktopChanges ? '2px 35px' : '7px 30px',
  },
}))

export const IconButtonRoot = Style('button')((props: ButtonProps) =>({
  backgroundColor: props.backgroundColor || 'transparent',
  border: 'none',
  borderRadius: 50,
  color: props.color,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: 20,
  opacity: props.disabled ? 0.5 : 1,
  padding: 0,
}))

export default ButtonRoot
