import React from 'react'

import { SectionBlock, SectionTitle } from '@pages/employee/Profile/Partials/Creator/Creator.Style'

const ParsingStart = () => {
	return (
		<div>
			<SectionTitle style={{ textAlign: 'center', marginTop: 70 }}>Dziękujemy za przesłanie pliku!</SectionTitle>
			<SectionBlock>Teraz rozpoczynamy jego przetwarzanie, co potrwa chwilę, ponieważ staramy się to zrobić jak najlepiej.</SectionBlock>
			<SectionBlock style={{ textAlign: 'center' }}>
				<img src="/praca2/assets/images/employee/creator/file_send.jpeg" alt="Kreator start" width="113px" height="130px"/>
			</SectionBlock>
			<SectionBlock style={{ textAlign: 'center' }}>W międzyczasie, prosimy o odpowiedź na kilka pytań dotyczących Twoich preferencji zawodowych.</SectionBlock>
			<SectionTitle style={{ textAlign: 'center' }}>Tylko 3 kroki dzielą Cię od Twojego nowego profilu!</SectionTitle>
		</div>
	)
}

export default ParsingStart
