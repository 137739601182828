import React, { useContext, useEffect, useReducer, useState } from 'react'
import { createContext } from 'react'
import _ from 'lodash'

import { INITIAL_SECTIONS_DATA } from '../constants'
import { CvFile, ProfileEditable, ProfileTwigData } from '../interfaces'
import {
	initializeSections,
	updateSection,
	userProfileReducer,
} from '../reducers/userProfileReducer'
import { getTotalPoints, twigToProfileEditable } from '../utils'

type UserProfileContextType = {
	sections: {
		[key in keyof ProfileEditable]: ProfileEditable[key]
	}
	files: Omit<CvFile, 'url'>[]
	parsingServiceAvailable: boolean
	completion: number
	initializeSectionsData: (twigData: ProfileTwigData) => void
	updateSectionData: <T extends keyof ProfileEditable>(
		key: T,
		values: ProfileEditable[T],
	) => void
}

type Props = {
	children: React.ReactNode
}

export const UserProfileContext = createContext<UserProfileContextType | null>(
	null,
)

export const UserProfileProvider = ({ children }: Props) => {
	const [ profileLoaded, setProfileLoaded ] = useState<boolean>(false)
	const [ files, setFiles ] = useState<Omit<CvFile, 'url'>[]>([])
	const [ parsingServiceAvailable, setParsingSectionAvailable ] =
		useState<boolean>(false)
	const [ userProfile, dispatch ] = useReducer(
		userProfileReducer,
		INITIAL_SECTIONS_DATA,
	)
	const [ completion, setCompletion ] = useState(0)

	useEffect(() => {
		if (!profileLoaded) return
		const totalPoints = getTotalPoints(
			_.omit(userProfile, [ 'agencyConsent', 'photo', 'lookingForJob', 'email' ]),
		)

		setCompletion(totalPoints)
	}, [userProfile])

	const initializeSectionsData = (twigData: ProfileTwigData) => {
		setFiles(twigData.files)
		setParsingSectionAvailable(twigData.parsingServiceAvailable)
		const parsedSectionsData = twigToProfileEditable(twigData)

		dispatch(initializeSections(parsedSectionsData))

		setProfileLoaded(true)
	}

	function updateSectionData<T extends keyof ProfileEditable>(
		key: T,
		values: ProfileEditable[T],
	) {
		dispatch(updateSection(key, values))
		const totalPoints = getTotalPoints(
			_.omit(userProfile, [ 'agencyConsent', 'photo', 'lookingForJob', 'email' ]),
		)

		setCompletion(totalPoints)
	}

	return (
		<UserProfileContext.Provider
			value={{
				files,
				parsingServiceAvailable,
				completion,
				initializeSectionsData,
				updateSectionData,
				sections: {
					...INITIAL_SECTIONS_DATA,
					...userProfile,
				},
			}}
		>
			{ children }
		</UserProfileContext.Provider>
	)
}

export const useUpdateSection = () => {
	const { updateSectionData } = useContext(UserProfileContext)

	return updateSectionData
}

export const useSectionData = (section: keyof ProfileEditable) => {
	const { sections } = useContext(UserProfileContext)

	return sections[section]
}

export const useSections = () => {
	const { sections } = useContext(UserProfileContext)

	return sections
}

export const useFiles = () => {
	const { files } = useContext(UserProfileContext)

	return files
}

export const useParsingSectionAvailable = () => {
	const { parsingServiceAvailable } = useContext(UserProfileContext)

	return parsingServiceAvailable
}
