import React from 'react'

import { ThemeProvider } from '@mui/material/styles'
import { FooterButtons } from '@pages/employee/Profile/interfaces'
import { ProfileModal } from '@pages/employee/Profile/Partials/PopupModal'
import { mainTheme } from '@sharedComponents/React/pracaMuiTheme'

interface Props {
	children: React.ReactNode
	closeFn: () => void
	saveFn: () => Promise<void>
	header: React.ReactElement | string,
	removeFn?: () => Promise<void>
}

export const SectionEdit = ({ children, closeFn, saveFn, header, removeFn }: Props) => {
	const getFooter = () => {
		const footer: FooterButtons = {
			rightButton: {
				fullWidth: !removeFn,
				text: 'Zapisz',
				action: saveFn,
			},
			leftButton: {
				text: 'Usuń',
				action: removeFn,
				colorTheme: 'error',
			}
		}

		if (!removeFn) {
			delete footer.leftButton
		}

		return footer
	}

	const close = async () => {
		closeFn()
		return true
	}

	return (
		<ProfileModal
			closeFn={ close }
			closeTitle='Anuluj'
			header={ header }
			footer={ getFooter() }
		>
			<ThemeProvider theme={ mainTheme } >
				{ children }
			</ThemeProvider>
		</ProfileModal>
	)
}