import { ProfileValidatable, ValidatorPath } from '../interfaces'

import {
	validateBasicText,
	validateBirthDate,
	validateCourseDate,
	validateExpectedSalary,
	validateExperienceEndDate,
	validateExperienceStartDate,
	validateExperienceYears,
	validateFirstOrLastName,
	validateLink,
	validatePhoneNumber,
	validatePresence,
	validateRadius,
	validateRadiusRequired,
	validateSummary,
} from './validators'

export interface DisableValidationOptions {
	disableRadius?: boolean
	disableLocation?: boolean
	disableSchoolName?: boolean
}
export const userProfileSchema = (
	disableValidationOptions?: DisableValidationOptions,
): Record<ProfileValidatable, ValidatorPath[]> => ({
	basicData: [
		[ 'firstName', false, [], validateFirstOrLastName ],
		[ 'lastName', false, [], validateFirstOrLastName ],
		[ 'phone', false, [], validatePhoneNumber ],
		[ 'birthdate', false, [], validateBirthDate ],
	],
	education: [
		[
			'',
			true,
			[[ 'schoolName', false, [[ 'title', false, [], disableValidationOptions?.disableSchoolName ? null : validatePresence ]], null ]],
			null,
		],
	],
	preferences: [
		[
			'preferredPosition',
			false,
			[
				[ 'position', false, [[ 'title', false, [], validateBasicText ]], null ],
				[ 'experienceYears', false, [], validateExperienceYears ],
			],
			null,
		],
		[
			'preferredLocation',
			false,
			[
				[
					'radius',
					false,
					[],
					disableValidationOptions?.disableRadius
						? validateRadius
						: validateRadiusRequired,
				],
				[
					'location',
					false,
					[
						[
							'title',
							false,
							[],
							disableValidationOptions?.disableLocation
								? null
								: validatePresence,
						],
					],
					null,
				],
			],
			null,
		],
		[ 'expectedSalary', false, [[ '', false, [], validateExpectedSalary ]], null ],
	],
	summary: [[ '', false, [], validateSummary ]],
	languages: [
		[
			'',
			true,
			[[ 'name', false, [[ 'title', false, [], validateBasicText ]], null ]],
			null,
		],
	],
	interests: [[ '', true, [[ '', false, [], validateBasicText ]], null ]],
	licences: [[ '', true, [[ 'title', false, [], validateBasicText ]], null ]],
	skills: [[ '', true, [[ 'title', false, [], validateBasicText ]], null ]],
	professionalExperience: [
		[
			'',
			true,
			[
				[ 'position', false, [[ 'title', false, [], validatePresence ]], null ],
				[ 'employer', false, [[ 'title', false, [], validatePresence ]], null ],
				[ 'startDate', false, [], validateExperienceStartDate ],
				[ 'endDate', false, [], validateExperienceEndDate ],
				[ 'responsibilities', true, [[ '', false, [], validatePresence ]], null ],
			],
			null,
		],
	],
	courses: [
		[
			'',
			true,
			[
				[ 'name', false, [], validatePresence ],
				[ 'endDate', false, [], validateCourseDate ],
			],
			null,
		],
	],
	links: [
		[
			'',
			true,
			[
				[ 'name', false, [], validatePresence ],
				[ 'url', false, [], validateLink ],
			],
			null,
		],
	],
})
