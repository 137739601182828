import React from 'react'

import { Icon } from '@sharedComponents/React'

import {
	SectionBlock,
	SectionTitle, successIcon,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'

const ParsingSuccess = () => {
	return (
		<div>
			<div>
				<SectionBlock style={{ textAlign: 'center', marginTop: 70 }}>
					<Icon type='badge-check' weight='light' className={ successIcon } />
				</SectionBlock>
				<SectionTitle style={{ textAlign: 'center', marginTop: 50 }}>
					Dane z Twojego CV zostały pomyślnie przetworzone!
				</SectionTitle>
				<SectionBlock style={{ textAlign: 'center' }}>
					Uzupełniliśmy Twój profil danymi z pliku CV.
				</SectionBlock>
				<SectionBlock style={{ textAlign: 'center' }}>
					Przejrzyj wszystkie informacje i potwierdź ich zgodność, wyedytuj lub odrzuć w razie konieczności.
				</SectionBlock>
			</div>
		</div>
	)
}

export default ParsingSuccess
