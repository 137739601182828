import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { cloneDeep } from 'lodash'
import uuid from 'uuid/v4'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import Button from '@mui/material/Button'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { BasicType, Language, LanguageLevel as LanguageLevelType } from '@pages/employee/Profile/interfaces'
import AutocompleteField from '@pages/employee/Profile/Partials/AutocompleteField'
import { createEmptyLanguage } from '@pages/employee/Profile/Partials/Creator/functions'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { Icon } from '@sharedComponents/React'

import { SectionBlock, SectionTitle } from '@pages/employee/Profile/Partials/Creator/Creator.Style'
import { deleteInlineIcon, EditItem } from '@pages/employee/Profile/Profile.Style'

export type Stage7Ref = {
	validateAll: () => boolean
	getCurrentData: () => Language[]
}

export declare type Stage7Types = Language[]

interface Stage7Props {
	updateForm: (value: Stage7Types) => void
	loading: boolean
	languages: Language[]
}

const Stage7 = forwardRef(function Stage7(props: Stage7Props, ref) {
	const { currentValue, errors, handleChange, validate, addItem, removeItem } =
		useEditSection('languages', props.languages.length > 0 ? props.languages : [createEmptyLanguage()])

	const itemsToRender = useRef<(Language & { renderID: string })[]>(
		currentValue.map(value => ({
			...cloneDeep(value),
			renderID: uuid(),
		})),
	)

	const scrollBlockRef = useRef<HTMLDivElement>()

	useImperativeHandle(ref, () => {
		return {
			validateAll(): boolean {
				return true
			},
			getCurrentData(): Language[] {
				return currentValue
			}
		}
	})

	const sendData = () => {
		if (validate()) {
			props.updateForm(currentValue)
		}
	}

	const updateLanguages = (isName: boolean, index: number, value: LanguageLevelType | BasicType) => {
		itemsToRender.current = itemsToRender.current.map(
			(language, langIndex) =>
				langIndex === index ? {
					...language,
					name: isName ? value as BasicType : language.name,
					level: isName ? language.level : value as LanguageLevelType
				} : language,
		)
	}

	const handleNameChange = (item: BasicType, index: number) => {
		handleChange(item, `[${ index }][name]`)
		updateLanguages(true, index, item)
	}

	const handleLevelChange = (item: LanguageLevelType, index: number) => {
		handleChange(item, `[${ index }][level]`)
		updateLanguages(false, index, item)
	}

	const handleAdd = () => {
		const emptyRecord = currentValue.find((item) => item.name.title === '')
		if (emptyRecord) {
			document.getElementById(`language_${ emptyRecord.name.title }`)?.focus()
		} else {
			addItem(createEmptyLanguage())
			itemsToRender.current.push({
				...createEmptyLanguage(),
				renderID: uuid(),
			})
			scrollBlockRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
		}
	}

	const handleRemove = async(index: number) => {
		removeItem(index)
		itemsToRender.current.splice(index, 1)
	}

	const getSelectedNames = () => currentValue.map((item) => item.name)

	return (
		<div>
			<SectionTitle>Czy znasz języki obce?</SectionTitle>
			<SectionBlock style={{ marginTop: 40 }}>
				{ itemsToRender.current.map((language, index) => (
					<EditItem key={ 'language_' + language.renderID } applyMargin>
						<div style={{ marginBottom: 30 }}>
							<AutocompleteField
								type='language'
								label='Nazwa języka obcego'
								dataValue={ language.name }
								hideSelected={ getSelectedNames() }
								error={ errors && errors?.[index]?.name?.title }
								handleChange={ (value: BasicType) => {
									if (value.title !== language?.name?.title) {
										handleNameChange(value || { title: '', value: 0 }, index)
									}
								} }
								handleBlur={ sendData }
								inputId={ 'language_' + language.name.value }
							/>

							<FormControl fullWidth disabled={ !language.name?.title }>
								<InputLabel id='langauge-level-label'>Poziom zaawansowania</InputLabel>
								<Select
									label='Poziom zaawansowania'
									labelId='langauge-level-label'
									value={ language.level || '' }
									defaultValue='a1_beginner'
									onChange={ ({ target }) =>
										handleLevelChange(target.value as LanguageLevelType, index)
									}
									onBlur={ sendData }
								>
									<MenuItem value='a1_beginner'>A1 – Początkujący</MenuItem>
									<MenuItem value='a2_elementary'>A2 – Podstawowy</MenuItem>
									<MenuItem value='b1_intermediate'>B1 – Średni</MenuItem>
									<MenuItem value='b2_upper_intermediate'>
										B2 – Średnio zaawansowany
									</MenuItem>
									<MenuItem value='c1_advanced'>C1 – Zaawansowany</MenuItem>
									<MenuItem value='c2_proficient'>C2 – Biegły</MenuItem>
								</Select>
							</FormControl>
						</div>

						<RoundedButton
							icon='trash-can'
							color='error'
							onClick={ () => handleRemove(index) }
							className={ deleteInlineIcon }
						/>
					</EditItem>
				)) }

				<div ref={ scrollBlockRef }>
					<Button
						onClick={ () => handleAdd() }
						startIcon={ <Icon type='plus' /> }
					>
						Dodaj język
					</Button>
				</div>
			</SectionBlock>
		</div>
	)
})
Stage7.displayName = 'Stage7'


export default Stage7
