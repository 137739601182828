import React, {
	forwardRef,
	ReactChild,
	ReactElement,
	ReactNode,
	useImperativeHandle,
	useState,
} from 'react'
import { cx } from 'react-emotion'

import { Modal } from '@components/React'
import Button from '@mui/material/Button'
import { ThemeProvider } from '@mui/material/styles'
import { FooterButtons } from '@pages/employee/Profile/interfaces'
import { Icon } from '@sharedComponents/React'
import { mainTheme, roundTheme } from '@sharedComponents/React/pracaMuiTheme'

import {
	BelowHeader,
	HeaderContent,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	spinner,
} from '@pages/employee/Profile/Partials/PopupModal/PopupModal.Style'

interface ModalProps {
	closeFn?: () => Promise<boolean>
	closeTitle: string
	header: ReactElement | string
	belowHeader?: ReactElement | string
	children: ReactChild
	footer?: FooterButtons
	footerAddon?: ReactNode
}

export type ModalRef = {
	closeModal: (skipAlert?: boolean) => void
}

export const ProfileModal = forwardRef(function ProfileModal(
	{
		closeFn,
		closeTitle,
		header,
		children,
		footer,
		belowHeader,
		footerAddon,
	}: ModalProps,
	ref,
) {
	const [ showModal, setShowModal ] = useState<boolean>(true)

	useImperativeHandle(ref, () => {
		return {
			closeModal(skipAlert?: boolean): void {
				closeHandler(skipAlert)
			},
		}
	})

	const closeHandler = (skipAlert?: boolean) => {
		if (closeFn && !skipAlert) {
			closeFn().then(shouldClose => {
				if (shouldClose) {
					setShowModal(false)
				}
			})
		} else {
			setShowModal(false)
		}
	}

	return (
		<Modal
			closeButton={ false }
			backdropColor
			show={ showModal }
			borderRounded
			fullScreenMedium
			noMaxHeight
			noTopBottomPadding
		>
			<ThemeProvider theme={ roundTheme }>
				<ModalBody style={{ position: 'relative' }}>
					<ModalHeader withMargin={ Boolean(belowHeader) }>
						<HeaderContent asText={ typeof header === 'string' }>
							{ header }
						</HeaderContent>
						<Button
							type='button'
							variant='text'
							onClick={ () => closeHandler() }
							sx={{ fontWeight: typeof header === 'string' ? 600 : 400 }}
						>
							{ closeTitle }
						</Button>
						{ belowHeader && <BelowHeader>{ belowHeader }</BelowHeader> }
					</ModalHeader>
					<ThemeProvider theme={ mainTheme }>
						<ModalContent withMargin={ Boolean(belowHeader) }>
							{ children }
						</ModalContent>
					</ThemeProvider>
					<ModalFooter>
						{ footer?.leftButton && (
							<>
								<Button
									disabled={ footer.leftButton.disabled }
									onClick={ footer.leftButton.action }
									variant={ footer.leftButton.style || 'contained' }
									color={ footer.leftButton.colorTheme || 'primary' }
									sx={{
										width: footer.leftButton.fullWidth ? '100%' : 'unset',
										textTransform: footer.leftButton.light ? 'none' : null,
										fontWeight: footer.leftButton.light ? 500 : null,
									}}
									endIcon={
										footer.leftButton.loading ? (
											<Icon
												type='spinner-third'
												weight='light'
												className={ cx('fa-spin', spinner) }
											/>
										) : null
									}
								>
									{ footer.leftButton.text }
								</Button>
							</>
						) }
						{ footer?.rightButton && (
							<>
								<Button
									disabled={ footer.rightButton.disabled }
									onClick={ footer.rightButton.action	}
									variant={ footer.rightButton.style || 'contained' }
									color={ footer.rightButton.colorTheme || 'primary' }
									sx={{
										width: footer.rightButton.fullWidth ? '100%' : 'unset',
										textTransform: footer.rightButton.light ? 'none' : null,
										fontWeight: footer.rightButton.light ? 500 : null,
									}}
									endIcon={
										footer.rightButton.loading ? (
											<Icon
												type='spinner-third'
												weight='light'
												className={ cx('fa-spin', spinner) }
											/>
										) : null
									}
								>
									{ footer.rightButton.text }
								</Button>
							</>
						) }
						{ footerAddon && footerAddon }
					</ModalFooter>
				</ModalBody>
			</ThemeProvider>
		</Modal>
	)
})

ProfileModal.displayName = 'ProfileModal'
