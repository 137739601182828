/* eslint-disable */
export declare type RequestType = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH'

const Fetch = async(
	type: RequestType,
	path: string,
	data?: any,
	formData?: boolean,
	cancelSignal?: AbortSignal,
): Promise<any> => {
	if (type === 'GET' || type === 'DELETE') {
		return await fetch(path, {
			credentials: 'same-origin',
			headers: {
				'Cache-Control': 'no-cache',
				'X-Requested-With': 'XMLHttpRequest',
			},
			method: type,
			mode: 'same-origin',
			signal: cancelSignal || null,
		})
	}

	const contentType = formData ? 'application/x-www-form-urlencoded; charset=UTF-8' : 'application/json'

	type RecursiveArray<Data> = Array<Data | RecursiveArray<Data>>

	const serialize = <Data = {}>(data: Data, prefix?: string): RecursiveArray<string> => {
		if (typeof data !== 'object' || data === null) {
			return []
		}

		return Object.keys(data).map((key) => {
			const objKey = prefix ? `${ prefix }[${ encodeURIComponent(key) }]` : encodeURIComponent(key)

			if (typeof data[key] === 'object') {
				return serialize(data[key], objKey)
			}

			return `${ objKey }=${ encodeURIComponent(data[key]) }`
		})
	}

	const convertObjectToString = () => {
		// @ts-ignore
		const parameters: string[] = serialize(data).flat(Infinity) // object can be infinite due to unknown data depth

		return parameters.join('&')
	}

	const formatData = () => formData ? convertObjectToString() : JSON.stringify(data)

	return await fetch(path, {
		body: data ? formatData() : '',
		credentials: 'same-origin',
		headers: {
			'Cache-Control': 'no-cache',
			'Content-Type': contentType,
			'X-Requested-With': 'XMLHttpRequest',
		},
		method: type,
		mode: 'same-origin',
	})
}

export default Fetch
