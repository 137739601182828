import React from 'react'

import Button from '@mui/material/Button'
import { useShowEdit } from '@pages/employee/Profile/hooks/useShowEdit'
import { Link } from '@pages/employee/Profile/interfaces'
import { LinksEdit } from '@pages/employee/Profile/Partials/Sections/LinksSection/LinksEdit'

import {
	ListItem, sectionButton,
	SectionContainer,
	SectionContent,
	SectionTitle,
} from '@pages/employee/Profile/Profile.Style'

interface LinksProps {
	links: Link[]
	updateData: (data: Link[]) => void
}

const Links = ({ links, updateData }: LinksProps) => {
	const { showEdit, toggleEdit } = useShowEdit()
	return links.length > 0 && (
		<>
			<SectionContainer>
				<SectionContent>
					<SectionTitle>Linki</SectionTitle>

					<ul>
						{ links.map(({ name, url }, index) => (
							<ListItem key={ 'link_' + index }>
								{ name && (
									<p>{ name }</p>
								) }
								<p>
									<a href={ url } target="_blank" rel="noreferrer">
										{ url }
									</a>
								</p>
							</ListItem>
						)) }
					</ul>
				</SectionContent>

				<Button
					variant="outlined"
					className={ sectionButton }
					onClick={ toggleEdit }
				>
					{ links.length ? 'Edytuj' : 'Dodaj' } linki
				</Button>
			</SectionContainer>

			{ showEdit && <LinksEdit
				close={ toggleEdit }
				links={ links }
				returnData={ updateData }
			/> }
		</>
	)
}

export default Links
