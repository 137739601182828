import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from 'react'

import { Chip, Stack, TextField } from '@mui/material'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { ContractType as ContractTypes, Preferences } from '@pages/employee/Profile/interfaces'
import { ContractType } from '@pages/employee/Profile/Partials/Sections/PreferencesSection'

import {
	numericInput,
	SectionBlock,
	SectionTitle,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'
import {
	chip,
} from '@pages/employee/Profile/Profile.Style'

export type Stage5Ref = {
	getCurrentData: () => { preferences: Preferences }
	validateAll: () => boolean
}

export declare type Stage5Field = 'expectedSalary' | 'contractType'
export declare type Stage5Types = number | ContractTypes[]

interface Stage5Props {
	updateForm: (value: Preferences) => void
	loading: boolean
	preferences: Preferences
}

const Stage5 = forwardRef(function Stage5(props: Stage5Props, ref) {
	const { currentValue, handleChange, validate, errors } = useEditSection('preferences', props.preferences)

	const [ edited, setEdited ] = useState<Array<Stage5Field>>([])

	useImperativeHandle(ref, () => {
		return {
			getCurrentData(): { preferences: Preferences } {
				return { preferences: currentValue }
			},
			validateAll(): boolean {
				return validate(['expectedSalary'])
			}
		}
	})

	useEffect(() => {
		if (props.preferences?.contractType?.length > 0) {
			editChange('contractType')
		}
		if (props.preferences?.expectedSalary) {
			editChange('expectedSalary')
		}
	}, [])

	useEffect(() => {
		sendUpdate()
	}, [currentValue?.contractType])

	const sendUpdate = () => {
		if (edited.length === 0 || !validate(['expectedSalary'])) {
			return
		}
		props.updateForm(currentValue)
	}

	const editChange = (fieldName: Stage5Field) => {
		if (!edited.includes(fieldName)) {
			setEdited(prev => ([ ...prev, fieldName ]))
		}
	}

	const changeHandler = (fieldName: Stage5Field, value: ContractTypes[] | number) => {
		editChange(fieldName)
		handleChange(value, `[${ fieldName }]`)
	}

	const renderChips = (): ReactNode[] => {
		const chipArray: ReactNode[] = []
		const changeWorkModel = (contractForm: ContractTypes) => {
			const newArray = [...currentValue.contractType]

			if (newArray.includes(contractForm)) {
				newArray.splice(newArray.indexOf(contractForm), 1)
			} else {
				newArray.push(contractForm)
			}

			changeHandler('contractType', newArray)
		}

		for (const key in ContractType) {
			chipArray.push(
				<Chip
					sx={ chip }
					variant={
						currentValue.contractType.includes(key as ContractTypes)
							? 'filled'
							: 'outlined'
					}
					color='primary'
					key={ key }
					label={ ContractType[key] }
					onClick={ () => { changeWorkModel(key as ContractTypes) } }
				/>,
			)
		}

		return chipArray
	}

	return (
		<div>
			<SectionTitle>Jakie są Twoje oczekiwania finansowe w pracy?</SectionTitle>
			<SectionBlock style={{ marginBottom: 20 }}>
				<TextField
					value={ currentValue.expectedSalary || '' }
					label='Wynagrodzenie miesięczne PLN brutto'
					variant='outlined'
					InputProps={{
						type: 'number',
					}}
					className={ numericInput }
					onChange={ ({ target }) =>
						changeHandler('expectedSalary', Number(target.value.trim().replace('.', ''))) }
					onBlur={ sendUpdate }
					fullWidth
					error={ Boolean(errors?.expectedSalary) }
					helperText={ errors?.expectedSalary }
				/>
			</SectionBlock>
			<SectionTitle>Jakie formy zatrudnienia preferujesz?</SectionTitle>
			<SectionBlock>
				<Stack
					direction='row'
					spacing='1'
					flexWrap='wrap'
					gap={ 1 }
					marginBottom={ 4 }
				>
					{ renderChips() }
				</Stack>
			</SectionBlock>
		</div>
	)
})
Stage5.displayName = 'Stage5'


export default Stage5
