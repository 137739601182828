import React, { useEffect, useRef } from 'react'

import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import {
	BasicType,
	Language,
	LanguageLevel,
} from '@pages/employee/Profile/interfaces'
import AutocompleteField from '@pages/employee/Profile/Partials/AutocompleteField'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { SectionEdit } from '@pages/employee/Profile/Partials/SectionEdit'
import { filterEmptyObjects, getRandomID } from '@pages/employee/Profile/utils'
import { Icon } from '@sharedComponents/React'

import {
	deleteInlineIcon,
	EditContentWrapper,
	EditItem,
} from '@pages/employee/Profile/Profile.Style'

const STARTING_VALUE = 'a1_beginner'
interface Props {
	close: () => void
	returnData?: (data: Language[]) => void
	languages: Language[]
}

type LanguageWithRenderID = Language & {
	renderID: string
}

const emptyLanguage: Language = {
	name: { title: '', value: null },
	level: STARTING_VALUE,
}

const LanguagesEdit = ({ close, languages, returnData }: Props) => {
	const { saveData, currentValue, removeItem, handleChange, errors, addItem } =
		useEditSection('languages', languages.length ? languages : [emptyLanguage])
	const itemsToRender = useRef<LanguageWithRenderID[]>(
		currentValue.map(value => ({
			...value,
			renderID: getRandomID(),
		})),
	)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const itemAdded = useRef<boolean>(false)

	const synchronizeLevel = (level: LanguageLevel, index: number) => {
		itemsToRender.current = itemsToRender.current.map((language, langIndex) =>
			langIndex === index
				? {
					...language,
					level,
				  }
				: language,
		)
	}

	const handleSave = async () => {
		let shouldClose = false
		if (returnData) {
			returnData(filterEmptyObjects(currentValue))
			shouldClose = true
		} else {
			shouldClose = await saveData()
		}

		if (shouldClose) {
			close()
		}
	}

	useEffect(() => {
		if (!itemAdded.current) return

		buttonRef.current.scrollIntoView({ behavior: 'smooth' })
		itemAdded.current = false
	}, [currentValue])

	const selectLabel = 'Poziom zaawansowania'

	return (
		<SectionEdit header='Języki obce' closeFn={ close } saveFn={ handleSave }>
			<EditContentWrapper>
				{ itemsToRender.current.map((language, index) => (
					<EditItem key={ language.renderID } className='language-item'>
						<div>
							<AutocompleteField
								required
								type='language'
								label='Nazwa języka obcego'
								dataValue={ language.name }
								error={ errors && errors?.[index]?.name?.title }
								handleChange={ (value: BasicType) =>
									handleChange(value, `[${ index }][name]`)
								}
							/>
							<FormControl fullWidth>
								<InputLabel
									id='langauge-level-label'
									error={ errors && errors?.[index]?.level }
								>
									{ selectLabel }
								</InputLabel>
								<Select
									labelId='langauge-level-label'
									value={ language.level || STARTING_VALUE }
									label={ selectLabel }
									onChange={ ({ target }) => {
										handleChange(target.value, `[${ index }][level]`)
										synchronizeLevel(target.value as LanguageLevel, index)
									} }
									error={ errors && errors?.[index]?.level }
								>
									<MenuItem value='a1_beginner'>A1 – Początkujący</MenuItem>
									<MenuItem value='a2_elementary'>A2 – Podstawowy</MenuItem>
									<MenuItem value='b1_intermediate'>B1 – Średni</MenuItem>
									<MenuItem value='b2_upper_intermediate'>
										B2 – Średnio zaawansowany
									</MenuItem>
									<MenuItem value='c1_advanced'>C1 – Zaawansowany</MenuItem>
									<MenuItem value='c2_proficient'>C2 – Biegły</MenuItem>
								</Select>
								<FormHelperText error>
									{ (errors && errors?.[index]?.level) || ' ' }
								</FormHelperText>
							</FormControl>
						</div>
						<RoundedButton
							icon='trash-can'
							color='error'
							onClick={ () => {
								removeItem(index)
								itemsToRender.current.splice(index, 1)
							} }
							className={ deleteInlineIcon }
						/>
					</EditItem>
				)) }
				<Button
					ref={ buttonRef }
					onClick={ () => {
						addItem(emptyLanguage)
						itemsToRender.current.push({
							...emptyLanguage,
							renderID: getRandomID(),
						})
						itemAdded.current = true
					} }
					startIcon={ <Icon type='plus' /> }
				>
					Dodaj język
				</Button>
			</EditContentWrapper>
		</SectionEdit>
	)
}

export default LanguagesEdit
