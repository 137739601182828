import React from 'react'

import { Button } from '@mui/material'
import { useShowEdit } from '@pages/employee/Profile/hooks/useShowEdit'
import { BasicType } from '@pages/employee/Profile/interfaces'
import LicencesEdit from '@pages/employee/Profile/Partials/Sections/LicencesSection/LicencesEdit'

import {
	ListItem,
	sectionButton,
	SectionContainer,
	SectionContent,
	SectionTitle,
} from '@pages/employee/Profile/Profile.Style'

interface LicensesProps {
	licences: BasicType[]
	updateData: (data: BasicType[]) => void
}

const Licenses = ({ licences, updateData }: LicensesProps) => {
	const { showEdit, toggleEdit } = useShowEdit()

	return licences.length > 0 && (
		<>
			<SectionContainer>
				<SectionContent>
					<SectionTitle>Uprawnienia / certyfikaty</SectionTitle>
					<ul>
						{ licences.map((item, index) => (
							<ListItem key={ 'licence_' + index }>{ item.title }</ListItem>
						)) }
					</ul>
				</SectionContent>

				<Button
					variant="outlined"
					className={ sectionButton }
					onClick={ toggleEdit }
				>
					{ licences.length ? 'Edytuj' : 'Dodaj' } uprawnienia / certyfikaty
				</Button>
			</SectionContainer>

			{ showEdit && <LicencesEdit
				close={ toggleEdit }
				licences={ licences }
				returnData={ updateData }
			/> }
		</>
	)
}

export default Licenses
