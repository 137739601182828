import { useState } from 'react'
import _ from 'lodash'

import { useGetActualCompletionScore } from '@pages/employee/Profile/hooks/useGetActualCompletionScore'
import {
	BasicDataForEdit,
	EditableItem,
	ProfileEditable,
} from '@pages/employee/Profile/interfaces'
import { useUpdateSection } from '@pages/employee/Profile/providers/UserProfileProvider'
import { DisableValidationOptions } from '@pages/employee/Profile/validations/schema'

import { updateProfile } from '../api'
import { validateSection } from '../validations/service'
import { validateProfileValidatable } from '../validations/validators'

type ValidationError<T> = T extends string | number
	? string
	: {
			[K in keyof T]: T[K] extends (infer U)[]
				? (U extends object ? ValidationError<U> : string)[]
				: T[K] extends object
				  ? ValidationError<T[K]>
				  : string
	  }

export const useEditSection = <T extends keyof ProfileEditable>(
	sectionName: T,
	sectionData: ProfileEditable[T],
) => {
	const [ currentValue, setCurrentValue ] =
		useState<ProfileEditable[T]>(sectionData)
	const [ errors, setErrors ] = useState(null)

	const updateSection = useUpdateSection()
	const getActualCompletionScore = useGetActualCompletionScore()

	const validate = (
		onlySelectedField?: string[],
		disableValidationOptions?: DisableValidationOptions,
	) => {
		if (!validateProfileValidatable(sectionName)) return true

		const { dataWithErrors, sectionValid } = validateSection(
			sectionName,
			currentValue as any,
			onlySelectedField,
			disableValidationOptions,
		)

		setErrors(dataWithErrors)
		return sectionValid
	}

	const saveData = async (newState: ProfileEditable[T] = null) => {
		if (!validate()) return
		const state = newState || currentValue

		let requestData
		if (sectionName === 'basicData') {
			requestData = _.cloneDeep(state) as BasicDataForEdit
			requestData.location.city = requestData.location?.city?.value ?? null
		} else {
			requestData = _.cloneDeep(state)
		}

		const completionScore = getActualCompletionScore(sectionName, state)

		try {
			await updateProfile(sectionName, requestData, completionScore)
			updateSection(sectionName, state)
			return true
		} catch (error) {
			console.error(error)
		}
	}

	const handleChange = (
		value: string | number | object | boolean,
		path?: string,
	) => {
		if (
			!path ||
			typeof currentValue === 'string' ||
			typeof currentValue === 'number'
		) {
			setCurrentValue(value as ProfileEditable[T])
			setErrors('')
		} else {
			const newState = _.cloneDeep(currentValue)

			const newErrors = errors && { ...errors }
			_.set(newState as any, path, value)
			_.set(newErrors, path, '')
			setCurrentValue(newState as ProfileEditable[T])
			setErrors(newErrors)
		}
	}

	const addItem = (item: EditableItem) => {
		setCurrentValue(prevState => {
			if (Array.isArray(prevState)) {
				return [ ...prevState, _.cloneDeep(item) ] as ProfileEditable[T]
			}
			return prevState
		})
	}

	const removeItem = async (index: number, sendData = false) => {
		if (!Array.isArray(currentValue)) return
		const stateToFilter = [...currentValue]

		const newState = stateToFilter.filter((_, itemIndex) => index !== itemIndex) as ProfileEditable[T]

		setErrors(prevErrors => {
			if (Array.isArray(prevErrors)) {
				return prevErrors.filter(
					(_, itemIndex) => itemIndex !== index,
				) as ValidationError<ProfileEditable[T]>
			}
			return prevErrors
		})

		if (sendData) await saveData(newState)
		setCurrentValue(newState as ProfileEditable[T])
	}

	return {
		currentValue,
		setCurrentValue,
		saveData,
		errors,
		setErrors,
		handleChange,
		addItem,
		removeItem,
		validate,
	}
}
