import Style, { css } from 'react-emotion'

import { Blue, Gray, Red, White } from '@styles/Color'
import MediaQuery from '@styles/MediaQueries'

interface ButtonRootProps {
  bold?: boolean
  borderless?: boolean,
  danger?: boolean,
  disabled?: boolean
  inverted?: boolean,
  withMobileDescription?: boolean,
}

export const ButtonRoot = Style('button')<ButtonRootProps>
(({ inverted, borderless, disabled, withMobileDescription, danger, bold }) => ({
  backgroundColor: disabled ? Gray[400] : inverted ? danger ? Red[500] : Blue[800] : White,
  border: '1px solid ' + ( borderless ? 'transparent' : Blue[800]),
  borderRadius: 25,
  color: inverted || disabled ? White : danger ? Red[500] : Blue[800],
  cursor: disabled ? 'default' : 'pointer',
  display: 'block',
  fontSize: bold ? 16 : 14,
  fontWeight: bold ? 600 : 400,
  minWidth: 45,
  outline: 'none !important',
  padding: withMobileDescription ? '10px 27px' : '8px 10px',
  textAlign: 'center',
  width: '100%',

  [MediaQuery.Large]: {
    padding: '7px 17px',
  },

  '&:hover': {
    backgroundColor: disabled ? Gray[400] : inverted ? danger ? Red[600] : Blue[900] : White,
  },
}))

export const ButtonLinkRoot = Style('a')<ButtonRootProps>
(({ inverted, borderless, disabled, withMobileDescription, danger, bold }) => ({
  backgroundColor: inverted ? danger ? Red[500] : Blue[800] : White,
  border: '1px solid ' + ( borderless ? 'transparent' : danger ? Red[500] : Blue[800]),
  borderRadius: 20,
  color: inverted ? White : danger ? Red[500] : Blue[800],
  cursor: disabled ? 'default' : 'pointer',
  display: 'block',
  fontWeight: bold ? 600 : 400,
  lineHeight: '20px',
  minWidth: 45,
  padding: withMobileDescription ? '10px 27px' : '8px 10px',
  textAlign: 'center',
  width: '100%',

  '&:hover': {
    color: inverted ? White : danger ? Red[500] : Blue[800],
  },

  [MediaQuery.Large]: {
    lineHeight: '15px',
    padding: '7px 17px',
  },
}))

export const buttonIcon = css({
  fontSize: 18,
  paddingRight: 10,

  [MediaQuery.Large]: {
    fontSize: 15,
  },
})

export const ButtonDescription = Style('span')<{ showOnMobile?: boolean }>(({ showOnMobile }) => ({
  display: showOnMobile ? 'inline' : 'none',
  fontSize: 15,
  fontWeight: 600,

  [MediaQuery.Large]: {
    display: 'inline',
    fontSize: 12,
  },
}))

export const PrimaryDescription = Style('span')<{ hideOnDesktop?: boolean }>(({ hideOnDesktop }) => ({
  [MediaQuery.Large]: {
    display: hideOnDesktop ? 'none' : 'inline',
  },
}))

export const DesktopDescription = Style('span')({
  display: 'none',

  [MediaQuery.Large]: {
    display: 'inline',
  },
})
