import React, { useEffect, useState } from 'react'

import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { Education } from '@pages/employee/Profile/interfaces'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { EducationDegree, EducationLevel } from '@pages/employee/Profile/Partials/Sections/EducationSection'
import EducationEdit from '@pages/employee/Profile/Partials/Sections/EducationSection/EducationEdit'

import { ButtonsBlock } from '@pages/employee/Profile/Partials/Creator/Creator.Style'
import {
	EditableSection,
	ListItem,
	SectionContainer,
	SectionContent,
	SectionTitle,
} from '@pages/employee/Profile/Profile.Style'

interface EducationProps {
	education: Education[]
	updateData: (data: Education[]) => void
}

const Education = ({ education, updateData }: EducationProps) => {
	const [ showEditIndex, setShowEditIndex ] = useState<number>(-1)
	const { currentValue, removeItem } =
		useEditSection('education', education)

	useEffect(() => {
		updateData(currentValue)
	}, [currentValue])

	const getEducationDetails = (education: Education) => {
		if (education.level === 'primary') {
			return <p>{ EducationLevel.primary }</p>
		}

		return (
			<>
				<p>{ EducationLevel[education.level] }</p>

				<p>
					{ education.schoolName && (
						<>{ education.schoolName.title }</>
					) }
				</p>

				<p>
					{ education.studiesMajor && (
						<>
							<span>{ education.studiesMajor?.title }</span>{ ' ' }
						</>
					) }
				</p>

				{ !education.stillLearning && education.level !== 'secondary' && (
					<p>
						{ (education.degree || education.profession) && (
							<>
								{ EducationDegree[education.degree] } { education?.profession?.title }
							</>
						) }
					</p>
				) }
			</>
		)
	}

	return education.length > 0 && (
		<SectionContainer>
			<SectionContent>
				<SectionTitle>Wykształcenie</SectionTitle>
				<ul>
					{ education.map((item, index) => (
						<ListItem key={ 'experience_' + index }>
							<EditableSection twoButtons>
								<div>
									{ getEducationDetails(item) }
								</div>
								<ButtonsBlock>
									<RoundedButton
										icon="trash-alt"
										color="error"
										onClick={ () => removeItem(index) }
									/>
									<RoundedButton
										icon="pencil"
										onClick={ () => setShowEditIndex(index) }
									/>
								</ButtonsBlock>
							</EditableSection>

						</ListItem>
					)) }
				</ul>
			</SectionContent>
			{ showEditIndex > -1 && (
				<EducationEdit
					close={ () => setShowEditIndex(-1) }
					education={ education }
					editingIndex={ showEditIndex }
					preventSendData
				/>
			) }
		</SectionContainer>
	)
}

export default Education
