import Fetch from '@sharedAssets/functions/fetch'

import { ProfileEditable } from './interfaces'
const BASE_URL = '/profile'

export async function updateProfile<T extends keyof ProfileEditable>(
	section: T,
	data: ProfileEditable[T],
	completionScore: number,
): Promise<Response> {
	const response = await Fetch('PATCH', BASE_URL, {
		[section]: data,
		score: completionScore,
	})

	if (!response.ok) throw new Error('Wystąpił błąd podczas aktualizacji profilu')

	return response
}

export const updatePhoto = async (base64string: string): Promise<string> => {
	const response = await Fetch('POST', `${ BASE_URL }/photo`, {
		fileContent: base64string,
	})

	const res = await response.json()

	return res.url
}

export const deletePhoto = async (): Promise<Response> =>
	await Fetch('DELETE', `${ BASE_URL }/photo`)
