import React from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { plPL } from '@mui/x-date-pickers/locales/plPL'
import {
	BasicType,
	Course,
	Education as EducationType,
	Language,
	Link,
	ProfessionalExperience, ProfileEditable,
} from '@pages/employee/Profile/interfaces'
import Courses from '@pages/employee/Profile/Partials/Creator/Stages/ParsingSections/Courses'
import Education from '@pages/employee/Profile/Partials/Creator/Stages/ParsingSections/Education'
import Experience from '@pages/employee/Profile/Partials/Creator/Stages/ParsingSections/Experience'
import Interests from '@pages/employee/Profile/Partials/Creator/Stages/ParsingSections/Interests'
import Languages from '@pages/employee/Profile/Partials/Creator/Stages/ParsingSections/Languages'
import Licenses from '@pages/employee/Profile/Partials/Creator/Stages/ParsingSections/Licenses'
import Links from '@pages/employee/Profile/Partials/Creator/Stages/ParsingSections/Links'
import Skills from '@pages/employee/Profile/Partials/Creator/Stages/ParsingSections/Skills'

import { SectionBlock } from '@pages/employee/Profile/Partials/Creator/Creator.Style'

interface ParsingVerificationProps {
	courses: Course[]
	education:  EducationType[]
	links: Link[]
	languages: Language[]
	skills: BasicType[]
	licences: BasicType[]
	interests: string[]
	professionalExperience: ProfessionalExperience[]
	setData: <T extends keyof ProfileEditable>(key: T, values: ProfileEditable[T]) => void
}

const ParsingVerification = (props: ParsingVerificationProps) => {
	return (
		<div>
			<SectionBlock>Uzupełniliśmy dane z profilu, przetworzonymi danymi z pliku CV.</SectionBlock>
			<SectionBlock>Przejrzyj wszystkie informacje i potwierdź ich zgodność, wyedytuj lub odrzuć w razie konieczności.</SectionBlock>
			<LocalizationProvider
				dateAdapter={ AdapterMoment }
				localeText={{
					...plPL.components.MuiLocalizationProvider.defaultProps.localeText,
				}}
			>
				{ props.professionalExperience.length > 0 &&
					<Experience
						experience={ props.professionalExperience }
						updateData={ (data: ProfessionalExperience[]) => {
							props.setData('professionalExperience', data)
						} }
					/>
				}
				{ props.education.length > 0 &&
					<Education
						education={ props.education }
						updateData={ (data: EducationType[]) => {
							props.setData('education', data)
						} }
					/>
				}
				{ props.skills.length > 0 &&
					<Skills
						skills={ props.skills }
						updateData={ (data: BasicType[]) => {
							props.setData('skills', data)
						} }
					/>
				}
				{ props.languages.length > 0 &&
					<Languages
						languages={ props.languages }
						updateData={ (data: Language[]) => {
							props.setData('languages', data)
						} }
					/>
				}
				{ props.licences.length > 0 &&
					<Licenses
						licences={ props.licences }
						updateData={ (data: BasicType[]) => {
							props.setData('licences', data)
						} }
					/>
				}
				{ props.courses.length > 0 &&
					<Courses
						courses={ props.courses }
						updateData={ (data: Course[]) => {
							props.setData('courses', data)
						} }
					/>
				}
				{ props.interests.length > 0 &&
					<Interests
						interests={ props.interests }
						updateData={ (data: string[]) => {
							props.setData('interests', data)
						} }
					/>
				}
				{ props.links.length > 0 &&
					<Links
						links={ props.links }
						updateData={ (data: Link[]) => {
							props.setData('links', data)
						} }
					/>
				}
			</LocalizationProvider>
		</div>
	)
}

export default ParsingVerification
