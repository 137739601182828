import { ProfileEditable } from '../interfaces'

type INITIALIZE_ACTION = {
	type: 'initialize'
	payload: ProfileEditable
}

type UPATE_SECTION_ACTION<T extends keyof ProfileEditable> = {
	type: 'update'
	payload: {
		key: T
		values: ProfileEditable[T]
	}
}

type ACTION = INITIALIZE_ACTION | UPATE_SECTION_ACTION<keyof ProfileEditable>

export const userProfileReducer = (state: ProfileEditable, action: ACTION) => {
	const { type, payload } = action

	switch (type) {
	case 'initialize':
		return payload
	case 'update': {
		const { key, values } = payload

		return {
			...state,
			[key]: values,
		}
	}

	default:
		return state
	}
}

export const initializeSections = (
	sectionsData: ProfileEditable,
): INITIALIZE_ACTION => {
	return {
		type: 'initialize',
		payload: sectionsData,
	}
}

export const updateSection = <T extends keyof ProfileEditable>(
	key: T,
	values: ProfileEditable[T],
): UPATE_SECTION_ACTION<T> => {
	return {
		type: 'update',
		payload: {
			key,
			values,
		},
	}
}
