import * as React from 'react'
import Icon from '../Icon'

import { cx } from 'react-emotion'
import {
	buttonIcon, ButtonDescription, ButtonLinkRoot, ButtonRoot, DesktopDescription, PrimaryDescription,
} from './EmployerButton.Styled'

interface ButtonProps {
  link?: string,
  bold?: boolean,
  borderless?: boolean,
  children?,
  clickHandler?: (event) => void
  classNames?: {
    buttonClass?: string,
    iconClass?: string,
    descriptionClass?: string,
  }
  danger?: boolean,
  description?: string,
  desktopDescription?: string,
  disabled?: boolean,
  invertedColors?: boolean,
  icon?: {
    type: string,
    weight: 'regular' | 'solid' | 'light',
  },
  showDescriptionOnMobile?: boolean,
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename'
  type?: 'submit' | 'reset' | 'button'
}

const PplButton = (props: ButtonProps) => {
	const content = (
		<>
			{ props.icon && (
				<Icon
					className={ cx(buttonIcon, props.classNames?.iconClass || '') }
					type={ props.icon.type }
					weight={ props.icon.weight }
				/>
			) }
			{ props.description && (
				<ButtonDescription
					className={ props.classNames?.descriptionClass || '' }
					showOnMobile={ props.showDescriptionOnMobile }
				>
					<PrimaryDescription
						hideOnDesktop={ Boolean(props.desktopDescription) }
					>{ props.description }</PrimaryDescription>
					{ props.desktopDescription && <DesktopDescription>{ props.desktopDescription }</DesktopDescription> }
				</ButtonDescription>
			) }
			{ props.children }
		</>
	)

	return props.link ? (
		<ButtonLinkRoot
			bold={ props.bold }
			className={ props.classNames?.buttonClass || '' }
			danger={ props.danger }
			disabled={ props.disabled }
			inverted={ props.invertedColors }
			href={ props.link }
			type={ props.type || 'button' }
			target={ props.target || '_self' }
			borderless={ props.borderless }
			withMobileDescription={ props.showDescriptionOnMobile }
		>
			{ content }
		</ButtonLinkRoot>
	) : (
		<ButtonRoot
			className={ props.classNames?.buttonClass || '' }
			danger={ props.danger }
			bold={ props.bold }
			disabled={ props.disabled }
			inverted={ props.invertedColors }
			onClick={ props.clickHandler }
			type={ props.type || 'button' }
			borderless={ props.borderless }
			withMobileDescription={ props.showDescriptionOnMobile }
		>
			{ content }
		</ButtonRoot>
	)
}

export default PplButton
