import * as React from 'react'
import ButtonRoot, { IconButtonRoot, LinkButtonRoot } from './Button.Styled'

import Icon from '@sharedComponents/React/Icon'

export interface ButtonProps {
  asLink?: boolean,
  children?: any,
  className?: string,
  color?: string,
  backgroundColor?: string,
  disabled?: boolean,
  desktopChanges?: boolean,
  href?: string,
  iconData?: {
    type: string,
    weight: 'light' | 'solid' | 'regular' | 'brand',
  }
  onClick?: (event) => any,
  outlined?: boolean,
  width?: string,
  target?: '_blank'
  type?: 'button' | 'submit' | 'reset',
}

export const Button = (props: ButtonProps) => {
	if (props.asLink) {
		return <LinkButtonRoot { ...props } onClick={ props.onClick }>{ props.children }</LinkButtonRoot>
	}
	return <ButtonRoot { ...props } onClick={ props.onClick } type={ props.type || 'button' }>{ props.children }</ButtonRoot>
}

export default Button

export const IconButton = (props: ButtonProps) => {
	return <IconButtonRoot { ...props } onClick={ props.onClick }><Icon type={ props.iconData.type } weight={ props.iconData.weight }/></IconButtonRoot>
}
