export declare type RequestType = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH'

const Fetch = async(type: RequestType, path: string, data?: any, formData?: boolean, cancelSignal?: AbortSignal): Promise<Response> => {
	if (type === 'GET' || type === 'DELETE') {
		return await fetch(path, {
			credentials: 'same-origin',
			headers: {
				'Cache-Control': 'no-cache',
				'X-Requested-With': 'XMLHttpRequest',
			},
			method: type,
			mode: 'same-origin',
			signal: cancelSignal || null,
		})
	}

	const contentType = formData ? 'application/x-www-form-urlencoded; charset=UTF-8' : 'application/json'

	const convertObjectToString = () => {
		const parameters = []

		for (const [ key, value ] of Object.entries(data)) {
			let parsedValue = value

			if (typeof parsedValue === 'string') {
				parsedValue = encodeURIComponent(parsedValue)
			}
			parameters.push(encodeURIComponent(key) + '=' + parsedValue)
		}

		return parameters.join('&')
	}

	const formatData = () => formData ? convertObjectToString() : JSON.stringify(data)

	return await fetch(path, {
		method: type,
		mode: 'same-origin',
		credentials: 'same-origin',
		headers: {
			'Cache-Control': 'no-cache',
			'Content-Type': contentType,
			'X-Requested-With': 'XMLHttpRequest',
		},
		body: data ? formatData() : '',
		signal: cancelSignal || null,
	})
}

export default Fetch
