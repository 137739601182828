import swal from 'sweetalert2'
import uuid from 'uuid/v4'

import Fetch from '@services/Fetch'

import {
	isArrayOfBasicData,
	isArrayOfCourses,
	isArrayOfLanguages,
	isArrayOfStrings,
} from './validations/validators'
import { SECTION_PERCENTAGE } from './constants'
import {
	BasicDataForEdit,
	BasicType,
	Course,
	Language,
	Preferences,
	ProfileEditable,
	ProfilePointable,
	ProfileTwigData,
} from './interfaces'

export const getPointsForSection = <T extends keyof ProfilePointable>(
	section: T,
	payload: ProfilePointable[T],
): number => {
	switch (section) {
	case 'basicData': {
		const fields = payload as BasicDataForEdit
		return fields.firstName && fields.lastName
			? SECTION_PERCENTAGE[section]
			: 0
	}
	case 'preferences': {
		const fields = payload as Preferences

		let fieldCorrect = false

		if (
			fields.preferredPosition?.position?.title ||
			fields.contractType?.includes('b2b') ||
			fields.preferredLocation?.location?.title ||
			fields.preferredLocation?.workModel?.includes('remote')
		) {
			fieldCorrect = true
		}

		return fieldCorrect ? SECTION_PERCENTAGE[section] : 0
	}
	case 'summary': {
		const data = payload as string
		return data ? SECTION_PERCENTAGE[section] : 0
	}
	case 'professionalExperience':
	case 'education':
	case 'languages':
	case 'skills':
	case 'courses':
	case 'licences':
	case 'interests':
	case 'links': {
		const data = payload as Array<ProfilePointable[T]>
		return data.length > 0 ? SECTION_PERCENTAGE[section] : 0
	}

	default:
		console.error('Cannot determine percentage for given section')
		return 0
	}
}

export const getTotalPoints = (userProfile: ProfilePointable): number => {
	let points = 0

	for (const key in userProfile) {
		points += getPointsForSection(
			key as keyof ProfilePointable,
			userProfile[key],
		)
	}

	return points
}

export const twigToProfileEditable = (
	data: ProfileTwigData,
): ProfileEditable => {
	delete data.files

	const profileEditable = {
		...data.profile,
		email: data.email,
		agencyConsent: data.agencyConsent,
		photo: data.profile.basicData.photo?.url ?? null,
	}

	delete profileEditable.basicData.photo

	return profileEditable
}

export const fileToBase64 = (file: File): Promise<string> => {
	return new Promise(resolve => {
		const reader = new FileReader()
		reader.addEventListener(
			'load',
			() => resolve(reader.result.toString()),
			false,
		)
		reader.readAsDataURL(file)
	})
}

export const downloadCv = async () => {
	try {
		const response = await Fetch('GET', '/profile-download')

		if (!response.ok) {
			throw new Error()
		}

		const data = await response.json()

		const downloadAnchor = document.createElement('a')

		downloadAnchor.href = data.downloadLink
		downloadAnchor.download = data.name

		document.body.appendChild(downloadAnchor)

		downloadAnchor.click()

		document.body.removeChild(downloadAnchor)
	} catch (err) {
		swal({
			title: 'Wystąpił błąd',
			text: 'Nie udało się wygenerować CV',
			type: 'error',
		})
	}
}

export const getRandomID = (): string => uuid().toString()

export const getRandomBetween = (min: number, max: number) =>
	Math.floor(Math.random() * (max - min + 1) + min)

export function filterEmptyObjects(values: Language[]): Language[]
export function filterEmptyObjects(values: BasicType[]): BasicType[]
export function filterEmptyObjects(values: Course[]): Course[]
export function filterEmptyObjects(values: string[]): string[]
export function filterEmptyObjects(
	values: BasicType[] | Language[] | Course[] | string[],
) {
	if (isArrayOfLanguages(values))
		return values.filter(language => language.name.title)

	if (isArrayOfBasicData(values))
		return values.filter(basicType => basicType.title)

	if (isArrayOfCourses(values)) return values.filter(course => course.name)

	if (isArrayOfStrings(values)) return values.filter(value => value)

	return values
}
