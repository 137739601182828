import React, { useRef, useState } from 'react'

import { Icon } from '@components/React'

import { CollapseButton, collapseContainer } from '@pages/employee/Profile/Profile.Style'

interface ResponsibilitiesBlockProps {
	responsibilities: string[],
}

export const ResponsibilitiesBlock = ({ responsibilities }: ResponsibilitiesBlockProps) => {
	const [ isOpen, setIsOpen ] = useState<boolean>(false)
	const [ height, setHeight ] = useState<number>(0)
	const contentRef = useRef<HTMLDivElement | null>(null)

	const handleChangeCollapse = () => {
		setIsOpen((prevValue) => !prevValue)
		if (contentRef.current) {
			setHeight(isOpen ? 0 : contentRef.current.scrollHeight)
		}
	}

	if (!responsibilities.length) {
		return null
	}

	return (
		<div>
			<CollapseButton
				onClick={ handleChangeCollapse }
				open={ isOpen }
			>
				Obowiązki <Icon type="chevron-down" />
			</CollapseButton>
			<div
				ref={ contentRef }
				style={{ height }}
				className={ collapseContainer }
			>
				<ul>
					{ responsibilities.map((responsibility, index) => (
						<li key={ index }>{ responsibility }</li>
					)) }
				</ul>
			</div>
		</div>
	)
}