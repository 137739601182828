import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import uuid from 'uuid/v4'

import Button from '@mui/material/Button'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { BasicType } from '@pages/employee/Profile/interfaces'
import AutocompleteField from '@pages/employee/Profile/Partials/AutocompleteField'
import {
	createBasicObject,
} from '@pages/employee/Profile/Partials/Creator/functions'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { Icon } from '@sharedComponents/React'

import {
	Info,
	SectionBlock,
	SectionTitle,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'
import {
	deleteInlineIcon,
	EditItem,
} from '@pages/employee/Profile/Profile.Style'

export type Stage8Ref = {
	validateAll: () => boolean
	getCurrentData: () => { skills: BasicType[], licences: BasicType[] }
}

export declare type Stage8Field = 'skills' | 'licences'
export declare type Stage8Types = BasicType[]

interface Stage8Props {
	updateForm: (name: Stage8Field, value: Stage8Types) => void
	loading: {
		skills: boolean
		licences: boolean
	}
	skills: BasicType[]
	licences: BasicType[]
}

const Stage8 = forwardRef(function Stage8(props: Stage8Props, ref) {
	const licencesData = useEditSection('licences', props.licences.length > 0 ? props.licences : [createBasicObject()])
	const skillsData = useEditSection('skills', props.skills.length > 0 ? props.skills : [createBasicObject()])
	const [ edited, setEdited ] = useState<Array<Stage8Field>>([])
	const isInitialRender = useRef<boolean>(true)

	const licenceBlockRef = useRef<HTMLDivElement>()
	const skillBlockRef = useRef<HTMLDivElement>()

	const licencesToRender = useRef<(BasicType & { renderID: string })[]>(
		licencesData.currentValue.map(value => ({
			...value,
			renderID: uuid(),
		})),
	)
	const skillsToRender = useRef<(BasicType & { renderID: string })[]>(
		skillsData.currentValue.map(value => ({
			...value,
			renderID: uuid(),
		})),
	)

	useImperativeHandle(ref, () => {
		return {
			validateAll(): boolean {
				return (edited.includes('licences') ? licencesData.validate() : true) &&
					(edited.includes('skills') ? skillsData.validate() : true)
			},
			getCurrentData(): { skills: BasicType[], licences: BasicType[] } {
				return { skills: skillsData.currentValue, licences: licencesData.currentValue }
			}
		}
	})

	useEffect(() => {
		if(isInitialRender.current) return

		sendData('licences')
	}, [licencesData.currentValue])

	useEffect(() => {
		if(isInitialRender.current) return

		sendData('skills')
	}, [skillsData.currentValue])

	useEffect(() => {
		isInitialRender.current = false
	}, [])

	const sendData = (fieldType: Stage8Field) => {
		switch (fieldType) {
		case 'licences':
			if (licencesData.validate()) {
				props.updateForm('licences', licencesData.currentValue)
			}
			break
		case 'skills':
			if (skillsData.validate()) {
				props.updateForm('skills', skillsData.currentValue)
			}
			break
		}
	}

	const editChange = (fieldName: Stage8Field) => {
		if (!edited.includes(fieldName)) {
			setEdited(prev => ([ ...prev, fieldName ]))
		}
	}

	const changeField = (fieldType: Stage8Field, value: BasicType, index: number) => {
		editChange(fieldType)
		switch (fieldType) {
		case 'licences':
			licencesData.handleChange(value, `[${ index }]`)
			break
		case 'skills':
			skillsData.handleChange(value, `[${ index }]`)
			break
		}
	}

	const addItem = (fieldType: Stage8Field) => {
		editChange(fieldType)
		switch (fieldType) {
		case 'skills':
			if (!skillsData.currentValue.find(skill => skill.value === '')) {
				skillsData.addItem(createBasicObject())
				skillsToRender.current.push({
					...createBasicObject(),
					renderID: uuid(),
				})
				skillBlockRef.current.scrollIntoView({ behavior: 'smooth' })
			}
			break
		case 'licences':
			if (!licencesData.currentValue.find(licence => licence.value === '')) {
				licencesData.addItem(createBasicObject())
				licencesToRender.current.push({
					...createBasicObject(),
					renderID: uuid(),
				})
				licenceBlockRef.current.scrollIntoView({ behavior: 'smooth' })
			}
			break
		}
	}

	const removeItem = (fieldType: Stage8Field, index: number) => {
		switch (fieldType) {
		case 'skills':
			skillsData.removeItem(index)
			skillsToRender.current.splice(index, 1)
			break
		case 'licences':
			licencesData.removeItem(index)
			licencesToRender.current.splice(index, 1)
			break
		}
		editChange(fieldType)
	}

	return (
		<div>
			<SectionTitle>Jakie są Twoje kluczowe umiejętności na poszukiwanym stanowisku?</SectionTitle>
			<Info><Icon type="info-circle" weight="light"/>Wpisuj każdą umiejętność osobno</Info>
			<SectionBlock style={{ marginBottom: 20 }}>
				{ skillsToRender.current.map((skill, index) => (
					<EditItem key={ 'skill' + skill.renderID }>
						<AutocompleteField
							type='skill'
							label=''
							dataValue={ skill }
							error={ skillsData.errors ? skillsData.errors?.[index]?.title : '' }
							handleChange={ (value: BasicType) => { changeField('skills', value, index) } }
							handleBlur={ () => sendData('skills') }
							multiline
						/>

						<RoundedButton
							icon='trash-can'
							color='error'
							onClick={ () => removeItem('skills', index) }
							className={ deleteInlineIcon }
						/>
					</EditItem>
				)) }
				<div ref={ skillBlockRef }>
					<Button onClick={ () => addItem('skills') } startIcon={ <Icon type='plus' /> }>
						Dodaj umiejętność
					</Button>
				</div>
			</SectionBlock>
			<SectionTitle>Czy posiadasz uprawnienia, które są ważne na tym stanowisku?</SectionTitle>
			<Info><Icon type="info-circle" weight="light"/>Wpisuj każde uprawnienie osobno</Info>
			<SectionBlock>
				{ licencesToRender.current.map((licence, index) => (
					<EditItem key={ 'licence' + licence.renderID }>
						<AutocompleteField
							type='licence'
							label=''
							dataValue={ licence }
							error={ licencesData.errors ? licencesData.errors?.[index]?.title : '' }
							handleChange={ (value: BasicType) => { changeField('licences', value, index) } }
							handleBlur={ () => sendData('licences') }
							multiline
						/>
						<RoundedButton
							icon='trash-can'
							color='error'
							onClick={ () => removeItem('licences', index) }
							className={ deleteInlineIcon }
						/>
					</EditItem>
				)) }

				<div ref={ licenceBlockRef }>
					<Button onClick={ () => addItem('licences') } startIcon={ <Icon type='plus' /> }>
						Dodaj uprawnienie
					</Button>
				</div>
			</SectionBlock>
		</div>
	)
})
Stage8.displayName = 'Stage8'


export default Stage8
