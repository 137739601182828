import React from 'react'

import Button from '@mui/material/Button'
import { useShowEdit } from '@pages/employee/Profile/hooks/useShowEdit'
import { Language } from '@pages/employee/Profile/interfaces'
import LanguagesEdit from '@pages/employee/Profile/Partials/Sections/LanguagesSection/LanguagesEdit'

import {
	ListItem,
	sectionButton,
	SectionContainer,
	SectionContent,
	SectionTitle,
	TextButton,
} from '@pages/employee/Profile/Profile.Style'

export enum LanguageLevel {
	a1_beginner = 'A1 – Początkujący',
	a2_elementary = 'A2 – Podstawowy',
	b1_intermediate = 'B1 – Średni',
	b2_upper_intermediate = 'B2 – Średnio zaawansowany',
	c1_advanced = 'C1 – Zaawansowany',
	c2_proficient = 'C2 – Biegły',
}

type Props = {
	languages: Language[]
}

export const LanguagesSection = ({ languages }: Props) => {
	const { showEdit, toggleEdit } = useShowEdit()

	return (
		<>
			<SectionContainer>
				<SectionContent>
					<SectionTitle>Języki obce</SectionTitle>

					{ languages.length ? (
						<ul>
							{ languages.map(({ name, level }, index) => (
								<ListItem key={ 'language_' + index }>
									<p>{ name.title }</p>
									{ level ? (
										<p>{ LanguageLevel[level] }</p>
									) : (
										<TextButton onClick={ toggleEdit }>+ Dodaj poziom</TextButton>
									) }
								</ListItem>
							)) }
						</ul>
					) : (
						<div>
							Pochwal się, jakimi językami obcymi się posługujesz i określ
							poziom ich znajomości.
						</div>
					) }
				</SectionContent>

				<Button
					variant='outlined'
					className={ sectionButton }
					onClick={ toggleEdit }
				>
					{ languages.length ? 'Edytuj' : 'Dodaj' } języki obce
				</Button>
			</SectionContainer>

			{ showEdit && <LanguagesEdit close={ toggleEdit } languages={ languages } /> }
		</>
	)
}
