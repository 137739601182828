import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { Chip, InputAdornment, Stack, TextField } from '@mui/material'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import {
	BasicType, BasicTypeWithType, Preferences,
	PreferredLocation, WorkModel as WorkModelType,
} from '@pages/employee/Profile/interfaces'
import AutocompleteField from '@pages/employee/Profile/Partials/AutocompleteField'
import {
	checkIfRadiusAllowed,
	checkLocationRequirement,
	checkRadiusRequirement,
} from '@pages/employee/Profile/Partials/Creator/functions'
import RadioYesNo from '@pages/employee/Profile/Partials/RadioYesNo'
import { WorkModel } from '@pages/employee/Profile/Partials/Sections/PreferencesSection'

import {
	radiusInput,
	SectionBlock,
	SectionTitle,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'
import {
	chip,
} from '@pages/employee/Profile/Profile.Style'

export type Stage4Ref = {
	validateAll: () => boolean
	getCurrentData: () => { preferences: Preferences }
}

export declare type Stage4Field = keyof PreferredLocation
export declare type Stage4Types = BasicType | boolean | number | WorkModelType[]

interface Stage4Props {
	updateForm: (value: Preferences) => void
	loading: string[]
	preferences: Preferences
}

const Stage4 = forwardRef(function Stage4(props: Stage4Props, ref) {
	const { currentValue, errors, handleChange, validate } = useEditSection('preferences', props.preferences)

	const [ edited, setEdited ] = useState<Array<Stage4Field>>([])

	const [ locationRequired, setLocationRequired ] = useState(checkLocationRequirement(currentValue.preferredLocation.workModel))
	const [ radiusRequired, setRadiusRequired ] = useState(checkRadiusRequirement(currentValue.preferredLocation.workModel))
	const [ radiusAllowed, setRadiusAllowed ] = useState(checkIfRadiusAllowed(currentValue.preferredLocation.location?.type))

	const [ locationInput, setLocationInput ] = useState<BasicTypeWithType>(currentValue.preferredLocation.location)

	useEffect(() => {
		const isLocationRequired = !checkLocationRequirement(currentValue.preferredLocation.workModel)
		const isRadiusRequired = checkRadiusRequirement(currentValue.preferredLocation.workModel)
	
		setLocationRequired(isLocationRequired)
		if (isRadiusRequired !== radiusRequired) {
			setRadiusRequired(isRadiusRequired)
		}
	}, [currentValue.preferredLocation.workModel])

	useEffect(() => {
		const isRadiusAllowed = checkIfRadiusAllowed(currentValue.preferredLocation.location?.type)

		if (!radiusAllowed && isRadiusAllowed) {
			handleChange(30, '[preferredLocation][radius]')
		}

		setRadiusAllowed(isRadiusAllowed)
		if (!isRadiusAllowed && radiusRequired) {
			setRadiusRequired(false)
		}
		if (!isRadiusAllowed && currentValue.preferredLocation.radius) {
			handleChange(null, '[preferredLocation][radius]')
		}
	}, [currentValue.preferredLocation.location])

	useEffect(() => {
		sendUpdate(true)
	},  [ currentValue.preferredLocation.workModel, currentValue.preferredLocation.relocation ])

	useEffect(() => {
		const newEdited = []

		for (const [ key, value ] of Object.entries(props.preferences.preferredLocation)) {
			if (value) {
				newEdited.push(key)
			}
		}
		setEdited(newEdited)
	}, [])

	const validateAll = (editedFields = edited, disableLocation?: boolean) =>
		validate(editedFields, { disableRadius: !radiusRequired || !radiusAllowed, disableLocation: !locationRequired || disableLocation })

	useImperativeHandle(ref, () => {
		return {
			validateAll(): boolean {
				return validateAll([ 'workModel', 'location', 'radius', 'relocation' ])
			},
			getCurrentData(): { preferences: Preferences } {
				return { preferences: currentValue }
			}
		}
	})

	const sendUpdate = (disableLocation?: boolean) => {
		if (edited.length === 0 || !validateAll(null, disableLocation)) {
			return
		}
		props.updateForm(currentValue)
	}

	const editChange = (fieldName: Stage4Field) => {
		if (!edited.includes(fieldName)) {
			setEdited(prev => ([ ...prev, fieldName ]))
		}
	}

	const changeHandler = (fieldName: Stage4Field, value: BasicTypeWithType | boolean | number | WorkModelType[]) => {
		editChange(fieldName)
		handleChange(value, `[preferredLocation][${ fieldName }]`)
	}

	const blurHandler = (fieldName: Stage4Field) => {
		editChange(fieldName)
		sendUpdate()
	}


	const renderChips = (): ReactNode[] => {
		const chipArray: ReactNode[] = []
		const changeWorkModel = (workModelItem: WorkModelType) => {
			const newArray = [...currentValue.preferredLocation.workModel]

			if (newArray.includes(workModelItem)) {
				newArray.splice(newArray.indexOf(workModelItem), 1)
			} else {
				newArray.push(workModelItem)
			}
			changeHandler('workModel', newArray)
		}

		for (const key in WorkModel) {
			chipArray.push(
				<Chip
					sx={ chip }
					variant={
						currentValue.preferredLocation.workModel.includes(key as WorkModelType)
							? 'filled'
							: 'outlined'
					}
					color='primary'
					key={ key }
					label={ WorkModel[key] }
					onClick={ () => changeWorkModel(key as WorkModelType) }
				/>,
			)
		}

		return chipArray
	}

	return (
		<div>
			<SectionTitle>Jaki tryb pracy preferujesz?</SectionTitle>
			<SectionBlock>
				<Stack
					direction='row'
					spacing='1'
					flexWrap='wrap'
					gap={ 1 }
					marginBottom={ 4 }
				>
					{ renderChips() }
				</Stack>
			</SectionBlock>
			<SectionTitle>Gdzie chcesz pracować?</SectionTitle>
			<SectionBlock>
				<AutocompleteField
					error={ errors?.preferredLocation?.location?.title ?? '' }
					required={ locationRequired }
					type='place'
					label='Miejscowość, województwo lub kraj'
					dataValue={ locationInput }
					onlyDictionaryAnswers
					handleChange={ (value: BasicTypeWithType) => changeHandler('location', value)  }
					handleBlur={ () => blurHandler('location') }
				/>
			</SectionBlock>
			<SectionTitle>Gotowość na dojazdy do pracy</SectionTitle>
			<SectionBlock style={{ marginBottom: 20 }}>
				<TextField
					helperText={ errors?.preferredLocation?.radius ?? 'Max. 500 km' }
					error={ Boolean(errors?.preferredLocation?.radius) }
					value={ currentValue.preferredLocation.radius || null }
					label="Promień"
					variant='outlined'
					InputProps={{
						type: 'number',
						startAdornment: <InputAdornment sx={{ marginRight: 2 }} position='start'>+</InputAdornment>,
						endAdornment: <InputAdornment position='end'>km</InputAdornment>,
						disabled: !currentValue.preferredLocation.location?.value || !radiusAllowed,
						placeholder: '0'
					}}
					required={ radiusRequired }
					onChange={ ({ target }) => changeHandler('radius', Number(target.value)) }
					onBlur={ () => blurHandler('radius') }
					fullWidth
					className={ radiusInput }
					disabled={ !currentValue.preferredLocation.location?.value || !radiusAllowed }
				/>
			</SectionBlock>
			<SectionTitle>Czy jesteś otwarty/a na przeprowadzkę do innego miasta/państwa w
				celu objęcia nowego stanowiska, jeśli pracodawca pokryje koszty
				relokacji?</SectionTitle>
			<SectionBlock>
				<RadioYesNo
					onValueChange={ (value) => changeHandler('relocation', value) }
					value={ currentValue.preferredLocation.relocation }
					withoutDefault
				/>
			</SectionBlock>
		</div>
	)
})
Stage4.displayName = 'Stage4'


export default Stage4
