import React from 'react'
import moment from 'moment/moment'

import { Button } from '@mui/material'
import { useShowEdit } from '@pages/employee/Profile/hooks/useShowEdit'
import {
	Course,
} from '@pages/employee/Profile/interfaces'
import CoursesEdit from '@pages/employee/Profile/Partials/Sections/CoursesSection/CoursesEdit'

import {
	ListItem, sectionButton,
	SectionContainer,
	SectionContent,
	SectionTitle,
} from '@pages/employee/Profile/Profile.Style'

interface CoursesProps {
	courses: Course[]
	updateData: (data: Course[]) => void
}

const Courses = ({ courses, updateData }: CoursesProps) => {
	const { showEdit, toggleEdit } = useShowEdit()

	return courses.length > 0 && (
		<>
			<SectionContainer>
				<SectionContent>
					<SectionTitle>Kursy / szkolenia</SectionTitle>
					<ul>
						{ courses.map(({ name, endDate }, index) => (
							<ListItem key={ 'course_' + index }>
								<p>{ name }</p>
								{ endDate && (
									<p>{ moment(endDate).format('YYYY-MM') }</p>
								) }
							</ListItem>
						)) }
					</ul>
				</SectionContent>

				<Button
					variant='outlined'
					className={ sectionButton }
					onClick={ toggleEdit }
				>
					{ courses.length ? 'Edytuj' : 'Dodaj' } kursy / szkolenia
				</Button>
			</SectionContainer>

			{ showEdit && <CoursesEdit
				close={ toggleEdit }
				courses={ courses }
				returnData={ updateData }
			/> }
		</>
	)
}

export default Courses
