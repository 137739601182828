import React, { useState } from 'react'

import Button from '@mui/material/Button'
import { BasicType } from '@pages/employee/Profile/interfaces'
import SkillsEdit from '@pages/employee/Profile/Partials/Sections/SkillsSection/SkillsEdit'

import {
	ListItem,
	sectionButton,
	SectionContainer,
	SectionContent,
	SectionTitle,
} from '@pages/employee/Profile/Profile.Style'

interface SkillsProps {
	skills: BasicType[]
	updateData: (data: BasicType[]) => void
}

const Skills = ({ skills, updateData }: SkillsProps) => {
	const [ showEdit, toggleEdit ] = useState<boolean>(false)

	return skills.length > 0 && (
		<>
			<SectionContainer>
				<SectionContent>
					<SectionTitle>Umiejętności</SectionTitle>
					<ul>
						{ skills.map(({ title }, index) => (
							<ListItem key={ 'skill_' + index }>{ title }</ListItem>
						)) }
					</ul>
				</SectionContent>

				<Button
					variant="outlined"
					className={ sectionButton }
					onClick={ () => toggleEdit(true) }
				>
					{ skills.length ? 'Edytuj' : 'Dodaj' } umiejętności
				</Button>
			</SectionContainer>

			{ showEdit && (
				<SkillsEdit
					close={ () => toggleEdit(false) }
					skills={ skills }
					returnData={ updateData }
				/>
			) }
		</>
	)
}

export default Skills
