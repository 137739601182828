/* eslint-disable */
import 'core-js/features/promise'
import 'regenerator-runtime/runtime'
import { LocalStorage } from '@components/Storage'
import { renderNotificationModal } from '@sharedComponents/React/NotificationModal'
import Fetch from '@sharedAssets/functions/fetch'
import { showCreator } from '@pages/employee/Profile/Partials/Creator'

declare global {
	interface Window {
		__loadNotification?: () => void,
	}
}

const desktopBreakpoint = window.matchMedia('screen and (min-width: 1024px)')
const docBody = document.body
const scriptsPaths = (window as any).__scriptsPath
const sharedScript = document.querySelector('script#sharedScript')

if (!sharedScript && scriptsPaths.shared.length > 0) {
	const newScript: HTMLScriptElement = document.createElement('script')

	newScript.setAttribute('defer', '')
	newScript.src = scriptsPaths.shared
	newScript.id = 'sharedScript'

	docBody.appendChild(newScript)
}

const loadScripts = (event, width?) => {
	const desktopScript = document.querySelector('script#desktopScript')
	const mobileScript = document.querySelector('script#mobileScript')

	const isDesktop = event?.matches || width >= 1024
	const newScript: HTMLScriptElement = document.createElement('script')

	newScript.setAttribute('defer', '')

	if (isDesktop && scriptsPaths?.desktop && !desktopScript) {
		newScript.src = scriptsPaths.desktop
		newScript.id = 'desktopScript'
	} else if (!isDesktop && scriptsPaths?.mobile && !mobileScript) {
		newScript.src = scriptsPaths.mobile
		newScript.id = 'mobileScript'
	}

	if (newScript) {
		docBody.appendChild(newScript)
	}
}

if (desktopBreakpoint.addEventListener) {
	desktopBreakpoint.addEventListener('change', loadScripts)
} else {
	desktopBreakpoint.addListener(loadScripts)
}

loadScripts(null, window.innerWidth)

// Web push

const loadNotification = () => {
	if (
		!('serviceWorker' in navigator) ||
    !('PushManager' in window) ||
    !('showNotification' in ServiceWorkerRegistration.prototype) ||
    Notification.permission === 'denied' ||
    window.__isEmployer
	) {
		return
	}

	const applicationServerKey =
    'BLczOofz2y_sr3Z-Sp7eoTUCAQoG1YMzN_WOOBd_GbYx8BL0eFyVaGsqVygo1agopS48hDQ0cWPZJ8AE5YTaXVg'

	let isSearchPage = false
	let isOfferPage = false
	let isApplyPage = false
	let isAfterApplyPage = false
	const isPushEnabled = Notification.permission === 'granted'
	let dismissExpired = true
	const parseArrayData = (data) => {
		const idsArray = []

		data.forEach((item) => {
			if (item.selected) {
				idsArray.push(item.id)
			}
		})

		return idsArray
	}

	const pageType = window.__wpPageName || 'none'
	const searchBoxData = window.__filtersData ? window.__filtersData : undefined
	let searchBoxPostData

	if (searchBoxData) {
		searchBoxPostData = {
			cityRadius: searchBoxData.cityRadius || null,
			employmentType: parseArrayData(searchBoxData.employmentType) || [],
			jobLevel: parseArrayData(searchBoxData.jobLevel) || [],
			locationPhrase: searchBoxData.locationPhrase || [],
			phrase: searchBoxData.phrase || [],
			professionalAreas: parseArrayData(searchBoxData.professionalAreas) || [],
			salary: searchBoxData.salary || null,
			workingTime: parseArrayData(searchBoxData.workingTime) || [],
			workModel: parseArrayData(searchBoxData.workModel) || [],
		}
	}

	// Check if page is inner/outer apply form or has search parameters

	if (pageType === 'ad' || pageType === 'offer') {
		isOfferPage = true
	} else if (location.pathname.indexOf('after-apply') > -1) {
		isAfterApplyPage = true
	} else if (pageType.indexOf('applying_show') > -1) {
		isApplyPage = true
	} else if (window.__filtersData) {
		const checkLength = [
			'employmentType',
			'jobLevel',
			'locationPhrase',
			'phrase',
			'professionalAreas',
			'workingTime',
		]
		const booleanCheck = [
			'remoteWork', 'relocation',
		]

		checkLength.map((key) => {
			if (searchBoxPostData[key].length > 0) {
				isSearchPage = true
				return
			}
		})

		booleanCheck.map((key) => {
			if (searchBoxPostData[key]) {
				isSearchPage = true
				return
			}
		})
	}

	const cookieName = 'notificationDismissed'

	if (LocalStorage.GetWithExpiry(cookieName)) {
		dismissExpired = false
	}

	const urlBase64ToUint8Array = (base64String) => {
		const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
		const base64 = (base64String + padding)
			.replace(/-/g, '+')
			.replace(/_/g, '/')

		const rawData = window.atob(base64)
		const outputArray = new Uint8Array(rawData.length)

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i)
		}
		return outputArray
	}

	const subscribe = () => {
		const subData = {
			filters: null,
			viewedOfferId: null,
			appliedOfferId: null,
		}

		if (isOfferPage) {
			subData.viewedOfferId = window.__pushOfferId
		} else if (isSearchPage) {
			subData.filters = searchBoxPostData
		} else if (isAfterApplyPage) {
			subData.appliedOfferId = window.__pushOfferId
		}

		return navigator.serviceWorker.ready
			.then((serviceWorkerRegistration) =>
				serviceWorkerRegistration.pushManager.subscribe({
					applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
					userVisibleOnly: true,
				}))
			.then((subscription) => sendSubscriptionToServer(subscription, 'POST', subData))
			.then((subscription) => subscription)
			.catch(() => { /**/ })
	}

	const updateSubscription = () => {
		const subData = {
			appliedOfferId: null,
			filters: null,
			viewedOfferId: null,
		}

		if (isOfferPage) {
			subData.viewedOfferId = window.__pushOfferId
		} else if (isSearchPage) {
			subData.filters = searchBoxPostData
		} else if (isApplyPage) {
			subData.appliedOfferId = window.__pushOfferId
		}

		navigator.serviceWorker.ready
			.then((serviceWorkerRegistration) => {
				return serviceWorkerRegistration.pushManager.getSubscription()
					.then((subscription) => {
						if (!subscription) {
							return null
						}
						return sendSubscriptionToServer(subscription, 'PUT', subData)
					})
					.catch(() => { /**/ })
			}).catch(() => { /**/ })
	}

  interface SubscriptionData {
    appliedOfferId?: number,
    filters?: string,
    viewedOfferId?: number,
  }

  const sendSubscriptionToServer = (subscription, method, subData: SubscriptionData) => {
  	const key = subscription.getKey('p256dh')
  	const token = subscription.getKey('auth')
  	const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]

  	return fetch(window.__webPushSubscribe, {
  		body: JSON.stringify({
  			appliedOfferId: subData.appliedOfferId || null,
  			authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
  			contentEncoding,
  			endpoint: subscription.endpoint,
  			listing: subData.filters || null,
  			publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
  			viewedOfferId: subData.viewedOfferId || null,
  		}),
  		credentials: 'same-origin',
  		headers: {
  			'Cache-Control': 'no-cache',
  			'Content-Type': 'application/json',
  			'X-Requested-With': 'XMLHttpRequest',
  		},
  		method,
  		mode: 'same-origin',
  	}).then(() => subscription).catch(() => subscription)
  }

  if ((isOfferPage || isSearchPage || isAfterApplyPage) && !isPushEnabled && dismissExpired) {
  	const popupBlock = document.createElement('div')

  	document.body.appendChild(popupBlock)

  	const denyHandler = () => {
			LocalStorage.SetWithExpiry(cookieName, 'true', 1)
  	}

  	const modalProps = {
  		description: 'Czy chcesz otrzymywać w przeglądarce powiadomienia o nowej pracy dopasowanej do Ciebie?',
  		functions: {
  			accept: subscribe,
  			close: denyHandler,
  			deny: denyHandler,
  		},
  		image: {
  			title: 'Praca.pl',
  			url: '/praca2/assets/images/logo.svg',
  		},
  		root: popupBlock,
  	}

		if (window.__disablePush) {
			return
		}

		window.__popupShown = true

  	setTimeout(() => {
  		renderNotificationModal(modalProps)
  	}, 3000)
  } else if ((isApplyPage || isOfferPage || isSearchPage) && isPushEnabled) {
  	updateSubscription()
  }
}

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', loadNotification)
} else {
	loadNotification()
}

window.__loadNotification = loadNotification

const shouldCreatorUpdate = async() => {
	const response = await Fetch('GET', '/profile/should-display-wizard')
	return await response.json()
}

if (window.__isEmployee) {
	if (typeof LocalStorage.Get('shouldDisplayWizard') !== 'boolean') {
		shouldCreatorUpdate().then((result) => {
			if (result.status === 'success' && result.data) {
				showCreator()
			}
			LocalStorage.Set('shouldDisplayWizard', false)
		})
	} else if (LocalStorage.Get('shouldDisplayWizard')) {
		showCreator()
		LocalStorage.Set('shouldDisplayWizard', false)
	}
}
