import React, { useState } from 'react'

import Button from '@mui/material/Button'
import { Education } from '@pages/employee/Profile/interfaces'

import { RoundedButton } from '../../RoundedButton'

import EducationEdit from './EducationEdit'

import {
	EditableSection,
	ListItem,
	sectionButton,
	SectionContainer,
	SectionContent,
	SectionTitle,
	TextButton,
} from '@pages/employee/Profile/Profile.Style'

export enum EducationLevel {
	primary = 'podstawowe',
	basic_vocational = 'zasadnicze zawodowe',
	basic_sectoral = 'zasadnicze branżowe',
	secondary_sectoral = 'średnie branżowe',
	secondary = 'średnie',
	higher = 'wyższe',
}

export enum EducationDegree {
	bachelors = 'licencjat',
	engineer = 'inżynier',
	masters = 'magister',
	master_of_engineering = 'magister inżynier',
	doctor = 'doktor',
	habilitated_doctor = 'doktor habilitowany',
	professor = 'profesor',
}

type Props = {
	education: Education[]
}

export const EducationSection = ({ education }: Props) => {
	const [ showEdit, setShowEdit ] = useState(false)
	const [ currentlyEditingIndex, setCurrentlyEditingIndex ] = useState<
		number | null
	>(null)

	const getEducationDetails = (education: Education, index: number) => {
		const isSectorial =
			education.level === 'basic_sectoral' ||
			education.level === 'basic_vocational' ||
			education.level === 'secondary_sectoral'

		if (education.level === 'primary') {
			return <p>{ EducationLevel.primary }</p>
		}

		return (
			<>
				<p>{ EducationLevel[education.level] }</p>

				<p>
					{ education.schoolName ? (
						<>{ education.schoolName.title }</>
					) : (
						<TextButton onClick={ () => handleToggleEdit(index) }>
							+ Dodaj nazwę uczelni / szkoły
						</TextButton>
					) }
				</p>

				<p>
					{ education.studiesMajor ? (
						<>
							<span>{ education.studiesMajor?.title }</span>{ ' ' }
						</>
					) : (
						<TextButton onClick={ () => handleToggleEdit(index) }>
							+ Dodaj nazwę kierunku
						</TextButton>
					) }
				</p>

				{ !education.stillLearning && education.level !== 'secondary' && (
					<p>
						{ education.degree || education.profession ? (
							<>
								{ EducationDegree[education.degree] } { education?.profession?.title }
							</>
						) : (
							<TextButton onClick={ () => handleToggleEdit(index) }>
								{ `+ Dodaj uzyskany ${ isSectorial ? 'zawód' : 'tytuł' }` }
							</TextButton>
						) }
					</p>
				) }
			</>
		)
	}

	const handleToggleEdit = (index?: number) => {
		if (typeof index === 'number') setCurrentlyEditingIndex(index)
		setShowEdit(true)
	}

	const handleCloseEdit = () => {
		setCurrentlyEditingIndex(null)
		setShowEdit(false)
	}

	return (
		<>
			<SectionContainer>
				<SectionContent>
					<SectionTitle>Wykształcenie</SectionTitle>

					{ education.length ? (
						<ul>
							{ education.map((item, index) => (
								<ListItem key={ 'education_' + index }>
									<EditableSection>
										<div>{ getEducationDetails(item, index) }</div>
										<RoundedButton
											icon='pencil'
											onClick={ () => handleToggleEdit(index) }
										/>
									</EditableSection>
								</ListItem>
							)) }
						</ul>
					) : (
						<div>Przedstaw szczegóły dotyczące Twojego wykształcenia.</div>
					) }
				</SectionContent>

				<Button
					variant='outlined'
					className={ sectionButton }
					onClick={ () => handleToggleEdit() }
				>
					Dodaj wykształcenie
				</Button>
			</SectionContainer>
			{ showEdit && (
				<EducationEdit
					close={ handleCloseEdit }
					education={ education }
					editingIndex={ currentlyEditingIndex }
				/>
			) }
		</>
	)
}
