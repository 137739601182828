import { useContext } from 'react'
import _ from 'lodash'

import {
	ProfileEditable,
	ProfilePointable,
} from '@pages/employee/Profile/interfaces'
import { UserProfileContext } from '@pages/employee/Profile/providers/UserProfileProvider'
import { getTotalPoints } from '@pages/employee/Profile/utils'

export const useGetActualCompletionScore = <
	T extends keyof ProfileEditable,
>() => {
	const {
		sections: { agencyConsent, ...userProfile },
	} = useContext(UserProfileContext)

	return (sectionName: T, state: ProfileEditable[T]) =>
		getTotalPoints(
			_.omit(
				{
					...userProfile,
					[sectionName]: state,
				},
				[ 'agencyConsent', 'photo', 'lookingForJob', 'email' ],
			) as ProfilePointable,
		)
}
