import { useState } from 'react'

export const useShowEdit = () => {
	const [ showEdit, setShowEdit ] = useState<boolean>(false)

	const toggleEdit = () => {
		setShowEdit((prev) => !prev)
	}

	return { showEdit, toggleEdit }
}