import React from 'react'

import { Icon } from '@sharedComponents/React'

import {
	dangerIcon,
	LinkButton,
	SectionBlock,
	SectionTitle,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'

const ParsingError = ({ backToStage2 }: { backToStage2: () => void }) => {
	return (
		<div>
			<SectionBlock style={{ textAlign: 'center', marginTop: 70 }}>
				<Icon type='exclamation-circle' weight='light' className={ dangerIcon }/>
			</SectionBlock>
			<SectionTitle style={{ textAlign: 'center', marginTop: 50 }}>
				Niestety, nie udało się przetworzyć Twojego pliku CV.
			</SectionTitle>
			<SectionBlock style={{ textAlign: 'center' }}>
				Spróbuj załączyć inny plik lub przejdź dalej - do kolejnych kroków kreatora.
			</SectionBlock>
			<SectionBlock style={{ textAlign: 'center', marginTop: 70 }}>
				<LinkButton onClick={ backToStage2 }>Załącz inny plik</LinkButton>
			</SectionBlock>
		</div>
	)
}

export default ParsingError
