import * as React from 'react'
import ReactDOM from 'react-dom'

import { Black } from '@styles/Color'
import { closeButtonIcon, Backdrop, CloseButton, Container, Content, FooterContent,
	HeaderContent, ModalFooter, ModalHeader } from './Modal.Styled'

import { Icon } from '@sharedComponents/React'

interface ModalProps {
  backdropColor?: boolean,
  children?: React.ReactNode,
  className?: string,
  clear?: boolean,
  closeButton?: boolean,
  closeColor?: string,
  disableBackdropClick?: boolean,
  disableBackground?: boolean,
  footer?: React.ReactNode,
  fullScreenMedium?: boolean,
  id?: string,
  notFullScreen?: boolean
  header?: React.ReactNode,
  onClose?: (clickEvent: ClickEvent<HTMLElement>) => void,
  show?: boolean,
  sizeClose?: number,
  title?: React.ReactNode,
  closeButtonNative?: boolean,
}

export default class Modal extends React.Component<ModalProps> {

	private static defaultProps = {
		children: null,
		closeButton: true,
		onClose: () => void 0,
		show: false,
		title: '',
	}

	private backdrop: HTMLDivElement
	private container: HTMLDivElement

	public render() {
		const { props } = this

		if (!props.show) {
			return null
		}

		const containerProps: { [key: string]: any } = {
			innerRef: (element) => { this.container = element },
		}

		if (props.className) {
			containerProps.className = props.className
		}

		return (
			<Backdrop backdropColor={ props.backdropColor }
				onClick={ props.disableBackdropClick ? null : this.handleBackdropClick }
				innerRef={ (element) => { this.backdrop = element } }>
				<Container
					{ ...containerProps }
					notFullScreen={ props.notFullScreen }
					fullScreenMedium={ props.fullScreenMedium }
					whiteBackground={ props.clear }
				>
					<ModalHeader shadowless={ props.clear } disableBackground={ props.disableBackground }>
						<HeaderContent>{ props.header || <></> }</HeaderContent>
						<CloseButton onClick={ (event) => props.onClose(event as undefined as ClickEvent<HTMLElement>) }>
							{ props.closeButtonNative ? 'X' : (
								<Icon
									className={ closeButtonIcon }
									style={ { color: props.closeColor || Black } }
									type="times"
									weight="light"
								/>
							) }
						</CloseButton>
					</ModalHeader>
					<Content>{ props.children }</Content>
					{ props.footer && <ModalFooter><FooterContent>{ props.footer }</FooterContent></ModalFooter> }
				</Container>
			</Backdrop>
		)
	}

	private handleBackdropClick = (event) => {
		if (event.target === this.backdrop) {
			this.props.onClose(event)
		}
	}
}

export const renderModal = (root: HTMLElement, props: ModalProps) => {
	ReactDOM.render((
		<Modal { ...props }>{ props.children }</Modal>
	), root)
}
