import React from 'react'

import Button from '@mui/material/Button'
import { useShowEdit } from '@pages/employee/Profile/hooks/useShowEdit'
import InterestsEdit from '@pages/employee/Profile/Partials/Sections/InterestsSection/InterestsEdit'

import {
	ListItem,
	sectionButton,
	SectionContainer,
	SectionContent,
	SectionTitle,
} from '@pages/employee/Profile/Profile.Style'

interface InterestsProps {
	interests: string[]
	updateData: (data: string[]) => void
}

const Interests = ({ interests, updateData }: InterestsProps) => {
	const { showEdit, toggleEdit } = useShowEdit()

	return interests.length > 0 && (
		<>
			<SectionContainer>
				<SectionContent>
					<SectionTitle>Zainteresowania</SectionTitle>
					<ul>
						{ interests.map((interest, index) => (
							<ListItem key={ 'interest_' + index }>{ interest }</ListItem>
						)) }
					</ul>
				</SectionContent>

				<Button
					variant="outlined"
					className={ sectionButton }
					onClick={ toggleEdit }
				>
					{ interests.length ? 'Edytuj' : 'Dodaj' } zainteresowania
				</Button>
			</SectionContainer>

			{ showEdit && <InterestsEdit
				close={ toggleEdit }
				interests={ interests }
				returnData={ updateData }
			/> }
		</>
	)
}

export default Interests
