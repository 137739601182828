import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'

import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { ProfessionalExperience } from '@pages/employee/Profile/interfaces'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import ExperienceEdit from '@pages/employee/Profile/Partials/Sections/ExperienceSection/ExperienceEdit'
import { ResponsibilitiesBlock } from '@pages/employee/Profile/Partials/Sections/ExperienceSection/ResponsibilitiesBlock'

import { ButtonsBlock } from '@pages/employee/Profile/Partials/Creator/Creator.Style'
import {
	EditableSection,
	ListItem,
	SectionContainer,
	SectionContent,
	SectionTitle,
	TextButton,
} from '@pages/employee/Profile/Profile.Style'

interface ExperienceProps {
	experience: ProfessionalExperience[]
	updateData: (data: ProfessionalExperience[]) => void
}

const Experience = ({ experience, updateData }: ExperienceProps) => {
	const [ showEditIndex, setShowEditIndex ] = useState<number>(-1)
	const { handleChange, currentValue, removeItem } = useEditSection(
		'professionalExperience',
		experience,
	)

	useEffect(() => {
		updateData(currentValue)
	}, [currentValue])

	const getDate = (
		startDate?: string,
		endDate?: string,
		currentlyWorking?: boolean,
		index?: number,
	): React.ReactNode => {
		let dateText: React.ReactNode

		if (startDate) {
			dateText = moment(startDate).format('YYYY.MM')
		} else {
			dateText = (
				<TextButton onClick={ () => setShowEditIndex(index) }>
					+ Dodaj datę rozpoczęcia
				</TextButton>
			)
		}

		if (endDate) {
			dateText = (
				<>
					{ dateText } - { moment(endDate).format('YYYY.MM') }
				</>
			)
		} else if (startDate && !currentlyWorking) {
			dateText = (
				<>
					{ dateText } -{ ' ' }
					<TextButton onClick={ () => setShowEditIndex(index) }>
						+ Dodaj datę zakończenia
					</TextButton>
				</>
			)
		} else if (!startDate && !currentlyWorking) {
			dateText = (
				<TextButton onClick={ () => setShowEditIndex(index) }>
					+ Dodaj datę
				</TextButton>
			)
		}

		return dateText
	}

	return (
		experience.length > 0 && (
			<SectionContainer>
				<SectionContent>
					<SectionTitle>Doświadczenie zawodowe</SectionTitle>
					<ul>
						{ experience.map((item, index) => (
							<ListItem key={ 'experience_' + index }>
								<EditableSection twoButtons>
									<div>
										{ item?.position?.title && <p>{ item.position.title }</p> }
										{ item?.employer?.title && <p>{ item.employer.title }</p> }
										{ getDate(
											item.startDate,
											item.endDate,
											item.currentlyWorking,
											index,
										) }
										{ item.currentlyWorking && !item.endDate && ' - obecnie' }
									</div>
									<ButtonsBlock>
										<RoundedButton
											icon='trash-alt'
											color='error'
											onClick={ () => removeItem(index) }
										/>
										<RoundedButton
											icon='pencil'
											onClick={ () => setShowEditIndex(index) }
										/>
									</ButtonsBlock>
								</EditableSection>
								<ResponsibilitiesBlock
									responsibilities={ item.responsibilities || [] }
								/>
							</ListItem>
						)) }
					</ul>
				</SectionContent>
				{ showEditIndex > -1 && (
					<ExperienceEdit
						experience={ experience }
						close={ () => setShowEditIndex(-1) }
						editIndex={ showEditIndex }
						returnData={ data => {
							handleChange(data)
							setShowEditIndex(-1)
						} }
					/>
				) }
			</SectionContainer>
		)
	)
}

export default Experience
