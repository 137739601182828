import React, { useEffect, useRef, useState } from 'react'
import { cx } from 'emotion'

import { Icon } from '@components/React'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { Link } from '@pages/employee/Profile/interfaces'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { SectionEdit } from '@pages/employee/Profile/Partials/SectionEdit'
import { validateSection } from '@pages/employee/Profile/validations/service'
import { validateUrl } from '@pages/employee/Profile/validations/validators'

import {
	deleteInlineIcon,
	EditContentWrapper,
	EditItem,
	fieldWithRedAsterisk,
	formField,
} from '@pages/employee/Profile/Profile.Style'

interface Props {
	close: () => void
	returnData?: (data: Link[]) => void
	links: Link[]
}

const emptyLink: Link = { name: '', url: '' }

export const LinksEdit = ({ close, links, returnData }: Props) => {
	const {
		currentValue,
		saveData,
		errors,
		addItem,
		removeItem,
		handleChange,
		setErrors,
	} = useEditSection('links', links.length ? links : [emptyLink])
	const [ linkErrors, setLinkErrors ] = useState([])
	const buttonRef = useRef<HTMLButtonElement>(null)
	const itemAdded = useRef<boolean>(false)

	const handleSave = async () => {
		let shouldClose = false
		if (returnData) {
			const { dataWithErrors, sectionValid } = validateSection(
				'links',
				currentValue,
			)
			setErrors(dataWithErrors)
			if (!sectionValid) return

			returnData(currentValue)
			shouldClose = true
		} else {
			if (linkErrors.length > 0) return
			shouldClose = await saveData()
		}
		if (shouldClose) {
			close()
		}
	}

	useEffect(() => {
		if (!itemAdded) return

		buttonRef.current.scrollIntoView({
			behavior: 'smooth',
		})
		itemAdded.current = false
	}, [currentValue])

	return (
		<SectionEdit header='Linki' closeFn={ close } saveFn={ handleSave }>
			<EditContentWrapper>
				{ currentValue.map((link, index) => (
					<EditItem key={ index } applyMargin>
						<div>
							<TextField
								required
								value={ link.name }
								label='Nazwa linku'
								variant='outlined'
								onChange={ ({ target }) =>
									handleChange(target.value, `[${ index }][name]`)
								}
								fullWidth
								className={ cx(formField, fieldWithRedAsterisk) }
								error={ errors && !!errors[index]?.name }
								helperText={
									(errors && errors[index]?.name) ||
									'Np: Linkedin, Github, portfolio...'
								}
							/>

							<TextField
								required
								value={ link.url }
								label='Adres url'
								variant='outlined'
								onBlur={ ({ target }) => {
									if (!target.value) return
									const newLinkErrors = [...linkErrors]
									const validationResult = validateUrl(target.value)
									if (validationResult) newLinkErrors[index] = validationResult
									setLinkErrors(newLinkErrors)
								} }
								onChange={ ({ target }) => {
									setLinkErrors([])
									handleChange(target.value, `[${ index }][url]`)
								} }
								error={
									(errors && !!errors[index]?.url) ||
									(linkErrors[index] && !!linkErrors[index])
								}
								helperText={
									linkErrors[index] ||
									(errors && errors[index]?.url) ||
									'Np. https://praca.pl'
								}
								fullWidth
								className={ cx(formField, fieldWithRedAsterisk) }
							/>
						</div>
						<RoundedButton
							icon='trash-can'
							color='error'
							onClick={ () => removeItem(index) }
							className={ deleteInlineIcon }
						/>
					</EditItem>
				)) }

				<Button
					ref={ buttonRef }
					onClick={ () => {
						addItem(emptyLink)
						itemAdded.current = true
					} }
					startIcon={ <Icon type='plus' /> }
				>
					Dodaj link
				</Button>
			</EditContentWrapper>
		</SectionEdit>
	)
}
