import React, { useEffect, useRef, useState } from 'react'

import { FormHelperText, TextField } from '@mui/material'

import { RoundedButton } from '../RoundedButton'

import { NumericInputWrapper } from '../../Profile.Style'

type Props = {
	value?: number
	onValueChange?: (currentValue: number) => void
	onBlur?: (currentValue: number) => void
	disabled?: boolean
	error?: string
}

const NumericInput = ({ onValueChange, onBlur, value, disabled, error }: Props) => {
	const [ currentValue, setCurrentValue ] = useState<number>(value || 0)

	const inputRef = useRef<HTMLInputElement>()

	const handleChangeValue = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	) => {
		const numericInput = Number(event.target.value) <= 99 ? Number(event.target.value) : 99
		if (isNaN(numericInput)) return

		setCurrentValue(numericInput)
		if (onValueChange) {
			onValueChange(numericInput)
		}
	}

	useEffect(() => {
		if (onValueChange) {
			onValueChange(currentValue)
		}
	}, [currentValue])

	const handleIncreaseValue = () => {
		const newValue = currentValue + 1 > 99 ? 99 : currentValue + 1

		setCurrentValue(newValue)
		if (onBlur && currentValue + 1 <= 99) {
			setTimeout(() => {
				if (newValue === Number(inputRef.current.value)) {
					onBlur(newValue)
				}
			}, 1000)
		}
	}

	const handleDecreaseValue = () => {
		const newValue = currentValue - 1 < 0 ? 0 : currentValue - 1

		setCurrentValue(newValue)
		if (onBlur && currentValue - 1 >= 0) {
			setTimeout(() => {
				if (newValue === Number(inputRef.current.value)) {
					onBlur(newValue)
				}
			}, 1000)
		}
	}

	return (
		<>
			<NumericInputWrapper title={ disabled ? 'Zapisywanie danych...' : '' }>
				<RoundedButton icon='circle-minus' onClick={ handleDecreaseValue } disabled={ disabled }/>
				<TextField
					sx={{
						width: 100,
					}}
					value={ currentValue }
					onChange={ event => handleChangeValue(event) }
					onBlur={ () => onBlur ? onBlur(currentValue) : void(0) }
					inputRef={ inputRef }
					disabled={ disabled }
					error={ Boolean(error) }

				/>
				<RoundedButton icon='circle-plus' onClick={ handleIncreaseValue } disabled={ disabled }/>
			</NumericInputWrapper>
			{ error && (
				<FormHelperText error={ Boolean(error) }>{ error }</FormHelperText>
			) }
		</>
	)
}

export default NumericInput
