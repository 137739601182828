import MediaQuery from '@styles/MediaQueries'
import Styled from 'react-emotion'

export const Logo = Styled('img')({
  margin: '-10px 0 10px',
  width: 80,
})

export const ModalContent = Styled('div')({
  maxWidth: 300,
  padding: '0 10px 20px',

  [MediaQuery.Large]: {
    maxWidth: 420,
  },
})

export const ModalDescription = Styled('div')({
  fontSize: 16,
  fontWeight: 300,
  marginBottom: 20,
})

export const ModalButtons = Styled('div')({
  display: 'grid',
  gap: '10px',
  gridTemplateColumns: '120px 120px',
  justifyContent: 'center',

  [MediaQuery.Medium]: {
    gap: '40px',
  },

  [MediaQuery.Large]: {
    gridTemplateColumns: '100px 100px',
  },
})
