import React, { useEffect, useRef, useState } from 'react'
import { cx } from 'react-emotion'

import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { getRandomBetween } from '@pages/employee/Profile/utils'

import ProgressBar from '../../ProgressBar'

import {
	activeHintIcon,
	Hint,
	hintIcon,
	HintItem,
	HintsBlock,
	HintsWrapper,
	ProgressContainer,
	SectionBlock,
	SectionTitle,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'

const hints = [
	<span key='hint_0'>
		Pierwsze wrażenie rekruter wyrabia sobie już w ciągu{ ' ' }
		<strong>7 sekund</strong> od rozpoczęcia rozmowy?
	</span>,
	<span key='hint_1'>
		Większość rekruterów spędza średnio tylko <strong>6 sekund</strong> na
		przeglądaniu życiorysu kandydata?
	</span>,
	<span key='hint_2'>
		Kandydaci, uzupełniający profile mają o <strong>70% większą szansę</strong>{ ' ' }
		na otrzymanie zaproszenia na rozmowę kwalifikacyjną?
	</span>,
]

const ParsingAwait = ({ parsingFinished }: { parsingFinished: boolean }) => {
	const [ progress, setProgress ] = useState<number>(0)
	const [ hintActive, setHintActive ] = useState<number>(1)
	const isInitialRender = useRef(true)

	useEffect(() => {
		if (isInitialRender.current) setProgress(progress + getRandomBetween(4, 12))

		isInitialRender.current = false
	}, [])

	useEffect(() => {
		if (parsingFinished) return

		const intervalID = setInterval(() => {
			if (progress >= 90) return
			setProgress(progress => progress + getRandomBetween(4, 12))
		}, 1000)

		return () => {
			clearInterval(intervalID)
		}
	}, [])

	const handleChangeHint = (changeValue: number) => {
		if (hintActive + changeValue > 2) {
			setHintActive(0)
		} else if (hintActive + changeValue < 0) {
			setHintActive(2)
		} else {
			setHintActive(hintActive + changeValue)
		}
	}

	return (
		<div>
			<SectionTitle style={{ textAlign: 'center', marginTop: 70 }}>
				Przetwarzanie pliku w toku
			</SectionTitle>
			<SectionBlock style={{ textAlign: 'center' }}>
				Prosimy o chwilę cierpliwości
			</SectionBlock>
			<SectionBlock style={{ textAlign: 'center' }}>
				<img
					src='/praca2/assets/images/employee/creator/file_processing.gif'
					alt='Przetwarzanie pliku'
					width='104px'
					height='130px'
				/>
			</SectionBlock>
			<ProgressContainer>
				<ProgressBar value={ parsingFinished ? 100 : progress } />
			</ProgressContainer>
			<SectionTitle style={{ textAlign: 'center', marginTop: 30 }}>
				Czy wiesz, że?
			</SectionTitle>
			<HintsWrapper>
				<HintsBlock>
					<RoundedButton
						icon='circle-chevron-left'
						onClick={ () => handleChangeHint(-1) }
						style={{ marginTop: -30 }}
					/>
					<HintItem>
						<Hint>{ hints[hintActive] }</Hint>
						<div>
							{ hints.map((hint, index) => (
								<i
									key={ 'hintDot_' + index }
									className={ cx(
										'fa-solid fa-circle',
										hintActive === index ? activeHintIcon : hintIcon,
									) }
								></i>
							)) }
						</div>
					</HintItem>
					<RoundedButton
						icon='circle-chevron-right'
						onClick={ () => handleChangeHint(1) }
						style={{ marginTop: -30 }}
					/>
				</HintsBlock>
			</HintsWrapper>
		</div>
	)
}

export default React.memo(ParsingAwait)
