export interface ValidationResult {
	success: boolean
	errors: string
}

export const fileTypes = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.oasis.opendocument.text',
	'application/pdf',
	'application/rtf',
	'text/plain'
]

export const validateFile = (file: File): ValidationResult => {
	const errors = []
	if ((file.size / 1e6) > 15) {
		errors.push('Przesłany plik jest za duży. Maksymalny rozmiar pliku to 15Mb')
	}

	if (!fileTypes.includes(file.type)) {
		errors.push('Przesłany plik ma nieprawidłowe rozszerzenie. Akceptowalne typy plików to .doc, .docx, .odt, .pdf, .rtf, .txt')
	}

	return { success: errors.length === 0, errors: errors.join('; ') }
}
