import {
	PreferredLocation,
	ProfileEditable,
	ProfilePointable,
} from './interfaces'

export const EXCLUDE_FROM_VALIDATE = ['agencyConsent']

export const INITIAL_SECTIONS_DATA: ProfileEditable = {
	email: '',
	photo: '',
	agencyConsent: {
		accepted: true,
		excludedEmployers: [],
	},
	basicData: {
		birthdate: new Date().toDateString(),
		firstName: '',
		lastName: '',
		phone: '',
		location: {
			countryId: null,
			provinceId: null,
			city: null,
			abroadCity: null,
			address: null,
			postalCode: null,
		},
	},
	courses: [],
	education: [],
	interests: [],
	languages: [],
	licences: [],
	links: [],
	lookingForJob: null,
	preferences: {
		preferredPosition: {
			hasExperience: false,
			experienceYears: null,
			level: null,
			position: null,
		},
		contractType: null,
		expectedSalary: null,
		preferredLocation: {
			location: null,
			radius: null,
			relocation: null,
			workModel: null,
		},
	},
	professionalExperience: [],
	skills: [],
	summary: '',
}

export const SECTION_PERCENTAGE: Record<keyof ProfilePointable, number> = {
	basicData: 15,
	preferences: 5,
	summary: 5,
	professionalExperience: 20,
	education: 15,
	languages: 10,
	skills: 10,
	licences: 5,
	courses: 5,
	links: 5,
	interests: 5,
}

export const STARTING_PREFERRED_LOCATION: PreferredLocation = {
	workModel: [],
	location: null,
	radius: null,
	relocation: null,
}

export const PARSE_CV_ERROR =
	'Aby pobrać CV lub zapisać CV na koncie, musisz uzupełnić swoje imię, nazwisko oraz doświadczenie lub wykształcenie.'
