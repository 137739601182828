import React, { forwardRef, useEffect, useImperativeHandle } from 'react'

import {
	FormControl,
	FormControlLabel, FormHelperText,
	InputLabel, MenuItem,
	Select, Switch,
} from '@mui/material'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import {
	BasicType, Degree, Degree as EducationDegreeType, Education,
	EducationLevel as EducationLevelType} from '@pages/employee/Profile/interfaces'
import AutocompleteField from '@pages/employee/Profile/Partials/AutocompleteField'
import { createStartData } from '@pages/employee/Profile/Partials/Creator/functions'
import { EducationDegree, EducationLevel } from '@pages/employee/Profile/Partials/Sections/EducationSection'

import {
	SectionBlock,
	SectionTitle,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'
import {
	selectField, switchField,
} from '@pages/employee/Profile/Profile.Style'

export type Stage6Ref = {
	validateAll: () => boolean
	getCurrentData: () => Education[]
}

export declare type Stage6Field = keyof Education
export declare type Stage6Types = EducationLevelType | boolean | BasicType | Degree

interface Stage6Props {
	updateForm: (value: Education[]) => void
	loading: boolean
	education: Education[]
}

const Stage6 = forwardRef(function Stage6(props: Stage6Props, ref) {
	const { currentValue, errors, handleChange, validate } =
		useEditSection('education', props.education.length > 0 ? props.education : createStartData().profile.education)
	const education = currentValue[0]

	useImperativeHandle(ref, () => {
		return {
			validateAll(): boolean {
				return validate(null,{ disableSchoolName: true })
			},
			getCurrentData(): Education[] {
				return currentValue
			}
		}
	})

	useEffect(() => {
		if ((isStillLearning || !isHigher) && education.degree) {
			handleChange(null, '[0][degree]')
		}
		if ((isStillLearning || isHigher) && education.profession) {
			handleChange(null, '[0][profession]')
		}
	}, [currentValue[0]])

	const isPrimaryLevel = education.level === 'primary'
	const isSecondaryLevel = education.level === 'secondary'
	const isHigher = education.level === 'higher'
	const isSectorial =
		education.level === 'basic_sectoral' ||
		education.level === 'basic_vocational' ||
		education.level === 'secondary_sectoral'
	const isStillLearning = education.stillLearning
	const shouldDisplayDegree = !(isStillLearning || isPrimaryLevel || isSecondaryLevel)

	const renderOptions = (obj: unknown) => {
		if (typeof obj !== 'object') return

		return Object.keys(obj).map(key => (
			<MenuItem key={ key } value={ key }>
				{ obj[key] }
			</MenuItem>
		))
	}

	const sendData = () => {
		if (validate(null,{ disableSchoolName: true })) {
			props.updateForm(currentValue)
		}
	}

	return (
		<div>
			<SectionTitle>Jaki jest Twój poziom wykształcenia?</SectionTitle>
			<SectionBlock style={{ marginTop: 40 }}>
				<FormControl fullWidth className={ selectField } error={ errors && errors[0]?.level }>
					<InputLabel id='education-level-label'>Wybierz poziom</InputLabel>
					<Select
						labelId='education-level-label'
						value={ education.level || '' }
						onChange={ ({ target }) => {
							handleChange(target.value as EducationLevelType || { title: ' ', value: 0 }, '[0][level]')
						} }
						onBlur={ sendData }
						label='Wybierz poziom'
						required
					>
						{ renderOptions(EducationLevel) }
					</Select>
					<FormHelperText>{ errors && errors[0]?.level }</FormHelperText>
				</FormControl>
				{ !isPrimaryLevel && (
					<>
						<AutocompleteField
							type='schoolName'
							error=''
							label='Nazwa uczelni/szkoły'
							dataValue={ education.schoolName || { title: '', value: 0 } }
							handleChange={ (value: BasicType) => {
								if (value.title !== education?.schoolName?.title) {
									handleChange(value || { title: ' ', value: 0 }, '[0][schoolName]')
								}
							} }
							handleBlur={ sendData }
						/>
						<AutocompleteField
							error=''
							type='studiesMajor'
							label='Kierunek'
							dataValue={ education.studiesMajor || { title: '', value: 0 } }
							handleChange={ (value: BasicType) => {
								if (value.title !== education?.studiesMajor?.title) {
									handleChange(value || { title: ' ', value: 0 }, '[0][studiesMajor]')
								}
							} }
							handleBlur={ sendData }
						/>
						<FormControlLabel
							className={ switchField }
							control={
								<Switch
									checked={ education.stillLearning }
									onChange={ ({ target }) => {
										handleChange(target.checked, '[0][stillLearning]')
										sendData()
									} }
								/>
							}
							label='Nadal się uczę'
						/>
					</>
				) }
				{ shouldDisplayDegree && (
					<>
						{ isSectorial ? (
							<AutocompleteField
								error=''
								type='profession'
								label='Uzyskany zawód'
								dataValue={ education.profession || { title: '', value: 0 } }
								handleChange={ (value: BasicType) => {
									if (value.title !== education?.profession?.title) {
										handleChange(value || { title: ' ', value: 0 }, '[0][profession]')
									}
								} }
								handleBlur={ sendData }
							/>
						) : (
							<FormControl fullWidth className={ selectField }>
								<InputLabel id='education-degree-label'>
									Uzyskany tytuł
								</InputLabel>
								<Select
									value={ education.degree || '' }
									labelId='education-degree-label'
									defaultValue='licencjat'
									label='Wybierz tytuł'
									required
									onChange={ ({ target }) => {
										handleChange(target.value as EducationDegreeType, '[0][degree]')
									} }
									onBlur={ sendData }
								>
									{ renderOptions(EducationDegree) }
								</Select>
							</FormControl>
						) }
					</>
				) }
			</SectionBlock>
		</div>
	)
})
Stage6.displayName = 'Stage6'


export default Stage6
